import { APP_INCOMING_TX_MAX_AGE } from '~/config';
import { getAvatarUrl, b64DecodeUnicode } from '~/helpers';
import { debugWrite } from '~/logging';

const debugLog = (...args) => debugWrite('Notifications', ...args);

export default {
    $store: null,

    init({ $store, $bus, $i18n }) {
        this.$store = $store;
        this.$i18n = $i18n;

        this.bootstrapNotifications();

        $bus.$on('newIncomingTx', (tx) => this.handleNewTx(tx));

        $store.watch((state) => state.settings.notifications, (isEnabled) => {
            const service = $store.state.services.notifications;
            if (isEnabled && (!service.supported || !service.allowed)) {
                this.bootstrapNotifications();
            }
        });
    },

    bootstrapNotifications() {
        if (!('Notification' in window)) {
            this.$store.commit('updateNotificationServiceConfig', { supported: false });
            this.$store.commit('updateSettings', { notifications: false });
            return debugLog('Notification API is not supported in this browser');
        }

        if (Notification.permission === 'granted') {
            this.$store.commit('updateNotificationServiceConfig', { allowed: true });
            return debugLog('Notification permission granted!');
        }

        if (Notification.permission == 'denied') {
            this.$store.commit('updateNotificationServiceConfig', { allowed: false });
            this.$store.commit('updateSettings', { notifications: false });
            return debugLog('Notification permission denied!');
        }

        // Костыль чтобы работало в Safari на Mac OS:
        if (Notification.permission !== 'denied') {
            Promise.resolve(Notification.requestPermission()).then((permission) => {
                this.$store.commit('updateNotificationServiceConfig', {
                    allowed: permission === 'granted',
                });

                if (permission !== 'granted') {
                    this.$store.commit('updateSettings', { notifications: false });
                }
            });
        }
    },

    /**
     * Проверяет все условия и показывает уведомление, если нужно.
     * @param {Object} tx Объект транзакции из API эксплорера
     */
    handleNewTx(tx) {
        const lastTxNumber = this.$store.state.services.notifications.lastTxNumber;

        // При любом исходе нам нужно запомнить последний номер транзакции:
        this.$store.commit('updateLastTxn', tx.txn);

        if (tx.txn <= lastTxNumber) {
            return debugLog(`Tx #${tx.txn} is already processed, skipping`);
        }

        // Если мы перезапускаем приложение, то номер последнего успешного уведомления
        // будет обнулён. Поэтому проверяем возраст транзакции:
        const timestamp = Date.parse(tx.timestamp);
        const diff = (Date.now() - timestamp) / 1000;

        if (diff > APP_INCOMING_TX_MAX_AGE) {
            return debugLog(`Tx #${tx.txn} is too old, skipping`);
        }

        if (!this.$store.getters.canSendNotifications) {
            return debugLog(`Cannot notify of tx#${tx.txn} because we can't send notifications`)
        }

        this.displayNotificationForIncomingTransaction(tx);
    },

    /**
     * Отображает браузерное уведомление.
     */
    displayNotificationForIncomingTransaction({ from, data, payload, hash }) {
        const txData = 'list' in data
            ? data.list.find(tx => tx.to == this.$store.state.wallet.address)
            : data;

        const amount = parseFloat(parseFloat(txData.value).toFixed(6));
        let title = this.$i18n.t('txHistory.notification.title');
        let body = `${amount} ${txData.coin.symbol}`;

        if (payload) {
            title = body;
            body = b64DecodeUnicode(payload);
        }

        return new Notification(title, {
            body, icon: getAvatarUrl(from),
        });
    },
};

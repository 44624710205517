import { objectKeysToSnake } from '~/helpers';
import { isCoinObject } from './utils';
import { EXPLORER_API_URL } from '~/config';
import axios from 'axios';

const http = axios.create({
    baseURL: EXPLORER_API_URL,
});

/**
 * @see https://github.com/MinterTeam/minter-explorer-api/tree/master/api/v2/validators
 * @return {Promise<Array>}
 */
export const getValidators = function invokeExplorerValidatorsMethod() {
    return http.get('/validators').then(({ data: { data: validators }}) => {
        return validators.map((validator) => ({
            ...validator,
            pub_key: validator.public_key,
            stake: parseFloat(validator.stake),
            voting_power: parseFloat(validator.part),
        }));
    });
};

/**
 * @see https://github.com/MinterTeam/minter-explorer-api/tree/master/api/v2/addresses
 * @param  {String} address
 * @return {Promise<Array>}
 */
export const getDelegations = function(address) {
    return http.get(`/addresses/${address}/delegations`).then(({ data: { data: stakes }}) => {
        return stakes.map((stake) => ({
            ...stake,
            value: parseFloat(stake.value),
            bip_value: parseFloat(stake.bip_value),
            value_exact: stake.value,
        }));
    });
};

/**
 * @param  {String}  address
 * @param  {AggregatedRewardsQueryRequest}
 * @return {Promise}
 */
export const getAggregatedRewards = function(address, { ...rawParams }) {
    const params = objectKeysToSnake(rawParams);
    return http.get(`/addresses/${address}/events/rewards/aggregated`, { params }).then((response) => {
        return response.data;
    });
};

/**
 * @param  {String}  address
 * @param  {AddressTransactionsQueryRequest}
 * @return {Promise}
 */
export const getTransactions = function(address, { ...rawParams }) {
    const params = objectKeysToSnake(rawParams);
    return http.get(`/addresses/${address}/transactions`, { params }).then((response) => {
        return response.data;
    });
};

/**
 * @see https://github.com/MinterTeam/minter-explorer-api/tree/master/api/v2/coins
 * @param  {String|Object} symbol
 * @return {Promise}
 */
export const searchCoins = function(symbol) {
    const params = !isCoinObject(symbol)
        ? { symbol: symbol.toUpperCase() }
        : symbol;

    return http.get('/coins', { params }).then((response) => {
        return response.data;
    });
};

export default {
    getValidators,
    getDelegations,
    getTransactions,
    searchCoins,
};

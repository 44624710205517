export const APP_VERSION = process.env.APP_VERSION;
export const APP_ENV = process.env.APP_ENV;
export const APP_SLS_KEY = process.env.APP_SLS_KEY;
export const APP_IS_ELECTRON = process.env.IS_ELECTRON;
export const APP_INCOMING_TX_MAX_AGE = process.env.APP_INCOMING_TX_MAX_AGE;
export const APP_DEFAULT_VALIDATOR = process.env.APP_DEFAULT_VALIDATOR;
export const TICK_SUSPEND_CHECK_MS = 4500;
export const TICK_UPDATE_BALANCE_MS = 4000;
export const TICK_UPDATE_STAKES_MS = 40000;
export const TICK_UPDATE_RECENT_ADDRESSES_MS = 300000;
export const TICK_REFRESH_EXCHANGE_RATES_MS = 180000;
export const COINGECKO_API_URL = process.env.COINGECKO_API_URL;
export const SENTRY_PROJECT = 'm1-wallet';
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const NODE_API_URL = process.env.NODE_API_URL;
export const EXPLORER_API_URL = process.env.EXPLORER_API_URL;
export const EXPLORER_RTM_URL = process.env.EXPLORER_RTM_URL;
export const EXPLORER_WEB_URL = process.env.EXPLORER_WEB_URL;
export const EXPLORER_ACCOUNTS_API_URL = process.env.EXPLORER_ACCOUNTS_API_URL;

export const VENDOR_MNS_API_ADDRESS = process.env.VENDOR_MNS_API_ADDRESS || 'https://mns.hashex.org';

export const BIP_VS_CURRENCIES = Object.freeze([
    'USD', 'EUR', 'RUB', 'CNY', 'BTC', 'ETH', 'BCH',
]);

<template>
    <section class="active-view-root">
        <header class="active-view-header-group">
            <el-page-header class="active-view__header"
                v-bind:class="{ 'hidden-m': hideDesktopTitle }"
                v-bind:title="$t('common.back')"
                v-on:back="$emit('back')"
                v-bind:content="title"/>
            <slot name="header"/>
        </header>
        <slot/>
    </section>
</template>

<script>
export default {
    props: {
        title: String,
        hideDesktopTitle: Boolean,
    },
};
</script>

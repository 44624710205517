<template>
    <div class="d8wt">
        <section v-if="!hasWallet">
            <wallet-add-modal v-on:walletCreated="walletCreated"/>

            <el-button class="button--fat" type="primary" v-on:click="$bus.$emit('modalWalletAdd:show')">
                <ui-icon name="lock"/> {{$t('login.mainButton')}}
            </el-button>
        </section>

        <section v-else>
            <d8-wallet/>

            <section class="d8-dash">
                <div v-once class="d8-dash_item d8-dash_item--settings" v-on:click="$bus.$emit('modalSettings:show')">
                    <ui-icon name="settings"/>
                </div>
                <div v-once class="d8-dash_item" v-on:click="$bus.$emit('modalStats:show')">
                    <ui-icon name="stats"/>
                </div>
                <div class="d8-dash_item d8-dash_item--spinner" v-bind:class="{ rotating }">
                    <ui-icon v-once name="sync"/>
                </div>
            </section>

            <validator-selector/>
            <d8-autodelegator/>

            <modal-tx-history/>
            <modal-action-delegate/>
            <modal-receive-coins/>
            <modal-send-coins/>
            <modal-my-coins/>
            <modal-settings/>
            <modal-stake/>
        </section>
    </div>
</template>

<script>
import D8Autodelegator from './Autodelegator';
import ModalTxHistory from './History/Main';
import ValidatorSelector from './Validator';
import ModalActionDelegate from './ModalActionDelegate';
import ModalReceiveCoins from './ModalReceiveCoins';
import ModalSendCoins from './ModalSendCoins';
import WalletAddModal from './ModalWalletAdd';
import ModalSettings from './ModalSettings';
import ModalMyCoins from './Coins/Main';
import ModalStake from './ModalStake';
import D8Wallet from './Wallet';
import { APP_DEFAULT_VALIDATOR } from '~/config';
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            walletAddress: state => state.wallet.address,
            validatorPubkey: state => state.validator.pubkey,
            rotating: state => state.services.autodelegator.isRunning,
        }),

        hasWallet() {
            return (this.walletAddress || '').length > 0;
        },

        hasPubkey() {
            return (this.validatorPubkey || '').length > 0;
        },
    },

    methods: {
        walletCreated(walletObject) {
            this.$store.commit('updateWallet', {
                privateKey: walletObject.getPrivateKeyString(),
                address: walletObject.getAddressString(),
            });

            // Если добавляем новый кошелёк, выбираем дефолтный валидатор:
            this.$store.commit('updateValidator', {
                pubkey: APP_DEFAULT_VALIDATOR,
            });

            this.$bus.$emit('modalWalletAdd:hide');
        },
    },

    components: {
        D8Autodelegator,
        ModalActionDelegate,
        ModalReceiveCoins,
        ModalSendCoins,
        WalletAddModal,
        ModalTxHistory,
        ModalSettings,
        ModalMyCoins,
        ModalStake,
        ValidatorSelector,
        D8Wallet,
    },
};
</script>

<template>
    <span>
        <animated-number
            v-if="!static"
            v-bind:duration="200"
            v-bind:value="value"
            v-bind:formatValue="valueReadable"/>
        <span v-else v-text="formattedValue"/>
    </span>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';

export default {
    props: {
        value: {
            default: 0.0,
        },
        maxLength: {
            default: 5,
        },
        fractionLength: {
            default: 0,
        },
        static: {
            default: false,
            type: Boolean,
        },
    },

    computed: {
        formattedValue() {
            return this.format(this.value);
        },
    },

    methods: {
        format(value) {
            const integer = parseInt(value);
            const maximumFractionDigits = Math.max(
                this.fractionLength,
                this.maxLength - integer.toFixed().length
            );

            // До 4 знаков форматирование не требуется:
            if (integer < 1000) {
                return value.toFixed(maximumFractionDigits);
            }

            // Нужно, чтобы количество знаков было ровно как мы посчитали:
            const minimumFractionDigits = maximumFractionDigits;

            return value.toLocaleString('en', {
                maximumFractionDigits,
                minimumFractionDigits,
            }).replace(/,/g, '\u2006'); // SIX-PER-EM SPACE
        },

        valueReadable(value) {
            const formatted = this.format(value);

            // Костыли: иногда в value передаётся 0 вместо числа:
            if (this.value > 0 && formatted == '0') {
                return format(this.value);
            }

            return formatted;
        },
    },

    components: {
        AnimatedNumber,
    },
};
</script>

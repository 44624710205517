<template>
    <el-input v-model="valueModel" v-on:change="checkMaxValue" class="active-view-input" placeholder="0">
        <div slot="append">
            <span class="active-view-input__max"
                v-if="this.showMax"
                v-on:click="setMax"
                v-text="$t('common.useMax')"/>
            <ui-coin-selector v-model="coinModel"/>
        </div>
    </el-input>
</template>

<script>
import Big from 'big.js';
import { isCoinObject } from '~/adapters/minter/utils';

export default {
    props: {
        value: {
            type: [Number, String],
            default: 0,
        },
        coin: {
            type: Object,
            required: true,
            validator: isCoinObject,
        },
        showMax: {
            type: Boolean,
            default: false,
        },
        commission: {
            type: [String, Number],
            default: 0,
        },
        gasCoin: {
            type: Object,
            required: true,
            validator: isCoinObject,
        },
    },

    computed: {
        balances() {
            return this.$store.getters.balances;
        },

        valueModel: {
            get() { return this.value; },
            set(value) {
                this.$emit('valueUpdated', value);
            },
        },

        coinModel: {
            get() { return this.coin; },
            set(coin) {
                this.$emit('coinUpdated', coin);
            },
        },

        maxValue() {
            let max = new Big(this.$store.getters.balanceBits(this.coinModel.id));

            if (parseInt(this.gasCoin.id) === parseInt(this.coinModel.id)) {
                max = max.minus(this.commission);
            }

            return max.gt(0) ? max.div(10 ** 18).toString() : 0;
        },
    },

    methods: {
        setMax() {
            this.valueModel = this.maxValue;
        },

        checkMaxValue() {
            this.$nextTick(() => {
                const value = new Big(this.valueModel);

                if (value.cmp(this.maxValue) > 0) {
                    this.valueModel = this.maxValue;
                }
            });
        },
    },
};
</script>

<style lang="scss">
.active-view-input__max {
    position: absolute;
    right: 100%;
    top: 50%;
    font-weight: 600;
    font-size: 14px;
    margin: -0.5em 8px 0 0;
    color: #c3c6cc;
    cursor: pointer;
    text-transform: uppercase;
    background: #FFF;
    line-height: 1;
    height: 1em;
    &::before {
        content: '';
        width: 18px;
        height: 100%;    
        position: absolute;
        right: 100%;
        top: 0;
        background: linear-gradient(to left, white, rgba(255, 255, 255, 0.001));
    }
    &:hover {
        color: inherit;
    }
}
</style>

import balances from './balances';
import MinterExplorerApi from '~/adapters/minter/ExplorerApi';
import { debugWrite } from '~/logging';
import {
    TICK_SUSPEND_CHECK_MS,
    TICK_UPDATE_BALANCE_MS,
    TICK_UPDATE_STAKES_MS,
    TICK_UPDATE_RECENT_ADDRESSES_MS,
} from '~/config';


const debugLog = (...args) => debugWrite('Background', ...args);

let balancesTimer;
let suspendCheckedAt;
let suspendCheckTimer;
let stakesTimer;
let recentAddressesTimer;

export default {
    created() {
        balances.init(this);
        balancesTimer = setInterval(() => balances.updateBalance(), TICK_UPDATE_BALANCE_MS);

        suspendCheckedAt = Date.now();
        suspendCheckTimer = setInterval(() => this.checkAppSuspend(), TICK_SUSPEND_CHECK_MS);

        this.updateStakes();
        stakesTimer = setInterval(() => this.updateStakes(), TICK_UPDATE_STAKES_MS);

        this.refreshRecentAddresses();
        recentAddressesTimer = setInterval(() => this.refreshRecentAddresses(), TICK_UPDATE_RECENT_ADDRESSES_MS);

        this.$store.watch(state => state.wallet.address, (address) => {
            if (address) this.updateStakes();
        });
    },

    beforeDestroy() {
        clearInterval(balancesTimer);
        balancesTimer = undefined;
        balances.destroy();

        clearInterval(suspendCheckTimer);
        suspendCheckTimer = undefined;
        suspendCheckedAt = undefined;

        clearInterval(stakesTimer);
        stakesTimer = undefined;

        clearInterval(recentAddressesTimer);
        recentAddressesTimer = undefined;
    },

    methods: {
        updateStakes() {
            if (this.$store.state.wallet.address) {
                this.$store.dispatch('UPDATE_STAKES');
            }
        },

        refreshRecentAddresses() {
            if (this.$store.state.wallet.address) {
                this.$store.dispatch('REFRESH_RECENT_ADDRESSES');
            }
        },

        checkAppSuspend() {
            const newTime = Date.now();
            const diffMs= newTime - suspendCheckedAt;

            suspendCheckedAt = newTime;

            if (diffMs > TICK_SUSPEND_CHECK_MS * 3) {
                debugLog(`The app was suspended ${diffMs} ms ago, waking up...`);
                this.$bus.$emit('app:wakeup');
            };
        },
    },
};

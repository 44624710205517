<template>
    <section>
        <el-form-item v-if="payload !== false" v-bind:label="$t('txCommon.form.messageLabel')">
            <el-input autosize show-word-limit
                type="textarea" rows="2"
                autocorrect="off" data-gramm="false" autocapitalize="off" spellcheck="false"
                v-bind:placeholder="$t('txCommon.form.messagePlaceholder')"
                v-bind:maxlength="1024"
                v-model="payloadModel">
            </el-input>
        </el-form-item>
        <el-row v-bind:gutter="24">
            <el-col v-bind:span="12">
                <el-form-item v-bind:label="$t('txCommon.form.comissionCoinLabel')">
                    <ui-coin-selector v-model="gasCoinModel"/>
                </el-form-item>
            </el-col> 
            <el-col v-if="chainId !== false" v-bind:span="12">
                <el-form-item v-bind:label="$t('txCommon.form.chainIdLabel')">
                    <el-select v-model="chainIdModel" disabled>
                        <el-option v-bind:value="1" label="MAINNET"/>
                        <el-option v-bind:value="2" label="TESTNET"/>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import { isCoinObject } from '~/adapters/minter/utils';

export default {
    props: {
        payload: {
            type: String,
            default: false,
        },
        chainId: {
            type: Number,
            default: false,
        },
        gasCoin: {
            type: Object,
            required: true,
            validator: isCoinObject,
        },
    },

    computed: {
        payloadModel: {
            get() { return this.payload },
            set(payload) {
                this.$emit('update', { payload });
            },
        },

        chainIdModel: {
            get() { return this.chainId },
            set(chainId) {
                this.$emit('update', { chainId });
            },
        },

        gasCoinModel: {
            get() { return this.gasCoin; },
            set(gasCoin) {
                this.$emit('update', { gasCoin });
            },
        },

        balances() {
            return this.$store.getters.balances;
        },
    },
};
</script>

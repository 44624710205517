<template>
    <div class="d8-coin">
        <img v-once v-if="!isPoolToken" class="d8-coin__avatar" v-bind:src="avatar">
        <span v-else class="d8-coin__avatar d8-coin__avatar--icon">
            <ui-icon name="toll"/>
        </span>
        <section class="d8-coin__info">
            <header class="d8-coin__title">
                <ui-bips v-if="displayCurrentBalance" class="d8-coin__balance" fractionLength="2" v-bind:value="balance"/>
                <span v-else class="d8-coin__symbol" v-text="symbol"/>

                <el-popover width="240" trigger="hover" placement="top-start"
                    v-bind:disabled="isBip || isPoolToken"
                    v-bind:title="symbol"
                    v-bind:open-delay="400">
                    <div class="d8-coin__info-description" v-if="coin.name" v-text="coin.name"/>
                    <table class="d8-coin__info-table" v-if="!coin.isToken">
                        <tr><td>CRR:</td><td v-text="`${coin.crr}%`"/></tr>
                        <tr><td>Volume:</td><td v-text="parseFloat(coin.volume).toFixed(6)"/></tr>
                        <tr><td>Reserve:</td><td v-text="parseFloat(coin.reserveBalance).toFixed(6)"/></tr>
                    </table>
                    <span slot="reference">
                        <i v-if="!displayCurrentBalance && !isBip" class="d8-coin__info-icon el-icon-info"/>
                        <span v-else class="d8-coin__symbol d8-coin__symbol--small" v-text="symbol"/>
                    </span>
                </el-popover>
            </header>

            <footer v-if="!isPoolToken" class="d8-coin__exchange-rates">
                <div v-if="!displayCurrentBalance && !isBip">
                    <span v-if="priceInBips < 1">
                        1 BIP ≈ <b v-text="Math.abs(1 / priceInBips).toFixed(4)"/> {{symbol}}
                    </span>
                    <span v-else>
                        1 {{symbol}} ≈ <b v-text="priceInBips.toFixed(4)"/> BIP
                    </span>
                </div>
                <div v-else>
                    <span v-if="!isBip">
                        <ui-bips static fractionLength="2" v-bind:value="bipsPrice"/> BIP
                    </span>
                    ≈ <ui-bips static fractionLength="2" v-bind:value="altPrice"/> {{altCurrency}}
                </div>
            </footer>

            <footer v-else class="d8-coin__exchange-rates" v-text="coin.name"/>
        </section>
    </div>
</template>

<script>
import { defaultCoin } from '~/adapters/minter/Schema';
import { getCoinInfoById, estimateCoinPrice } from '~/adapters/minter/NodeApi';
import { getCoinIconUrl } from '~/helpers';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },

        symbol: {
            type: String,
            required: true,
        },

        bipValue: {
            type: Number,
            required: false,
        },

        balance: {
            type: Number,
            required: false,
        },

        crr: {
            type: Number,
            required: false,
        },

        name: {
            type: String,
            required: false,
        },

        volume: {
            type: String,
            required: false,
        },

        reserveBalance: {
            type: String,
            required: false,
        },

        showBuyPrice: {
            type: Boolean,
            default: false,
        },

        isPoolToken: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            coin: {
                id: 0,
                name: 'BEEP-BEEP',
                crr: 100,
                volume: 1,
                reserveBalance: 1,
                exchangeRate: 0,
            },
        };
    },

    computed: {
        avatar() {
            return getCoinIconUrl(this.symbol);
        },

        isBip() {
            return this.id === defaultCoin.id;
        },

        displayCurrentBalance() {
            return this.balance != undefined;
        },

        priceInBips() {
            if (this.coin.exchangeRate) {
                return this.coin.exchangeRate;
            }

            return this.showBuyPrice ? this.buyPrice : this.sellPrice;
        },

        sellPrice() {
            const { reserveBalance, volume, crr } = this.coin;
            const coinAmount = Math.min(volume, 1);

            return reserveBalance * (1 - ((1 - coinAmount / volume) ** (1 / (crr / 100))));
        },

        buyPrice() {
            const coinAmount = 1;
            const { reserveBalance, volume, crr } = this.coin;

            return reserveBalance * (((1 + coinAmount / volume) ** (1 / (crr / 100))) - 1);
        },

        bipsPrice() {
            if (this.bipValue) {
                return this.bipValue;
            }

            return !this.isBip ? (this.balance * this.priceInBips) : 1;
        },

        altPrice() {
            return this.bipsPrice * this.$store.getters.selectedCurrency.rate;
        },

        altCurrency() {
            return this.$store.getters.selectedCurrency.symbol;
        },
    },

    created() {
        for (let key of ['id', 'crr', 'name', 'volume', 'reserveBalance']) {
            if (! this[key]) {
                return this.loadInfo();
                break;
            }

            this.coin[key] = this[key];
        }

        if (this.coin.crr == 0) {
            this.coin.isToken = true;
            this.estimatePriceInBip();
        }
    },

    methods: {
        loadInfo() {
            if (this.isBip) return;

            getCoinInfoById(this.id).then(data => {
                this.coin = { ...data,
                    volume: data.volume / 10 ** 18,
                    reserveBalance: data.reserve_balance / 10 ** 18,
                };

                this.coin.isToken && this.estimatePriceInBip();
            });
        },

        estimatePriceInBip() {
            estimateCoinPrice(this.coin).then(data => {
                this.$set(this.coin, 'exchangeRate', data.will_get / 10 ** 18);
            }).catch(e => void e);
        },
    },
};
</script>

<template>
    <section>
        <autodelegator-settings/>

        <footer class="d8wt__section d8wt__section--mobile-float-bottom">
            <el-button class="start-button button--fat" type="primary"
                v-bind:class="{ working: isRunning }"
                v-bind:disabled="!walletAddress || !validatorPubkey"
                v-on:click="toggleRedelegator">
                <ui-icon name="power"/> {{startButtonText}}
            </el-button>
        </footer>
    </section>
</template>

<script>
import AutodelegatorSettings from './AutodelegatorSettings';
import { mapState, mapMutations } from 'vuex';
import { defaultCoin } from '~/adapters/minter/Schema'
import Minter from '~/adapters/minter';
import Big from 'big.js';

export default {
    data() {
        return {
            commission: null,
        };
    },

    computed: {
        startButtonText() {
            return this.isRunning 
                ? this.$t('redelegator.button.stop')
                : this.$t('redelegator.button.start');
        },

        ...mapState({
            isRunning: state => state.services.autodelegator.isRunning,
            walletAddress: state => state.wallet.address,
            validatorPubkey: state => state.validator.pubkey,
            settings: ({ settings: { percent, floor }}) => ({ percent, floor }),
        }),
    },

    created() {
        this.estimateCommission();

        this.$store.watch((state) => state.wallet.balances, () => {
            if (this.isRunning) {
                this.reDelegate();
            }
        });

        this.$watch((vm) => [vm.validatorPubkey, vm.walletAddress], ([ newPubkey, newAddress ]) => {
            if (!newPubkey || !newAddress) {
                this.setAutodelegatorStatus(false);
            }
        });
    },

    methods: {
        ...mapMutations(['setAutodelegatorStatus']),

        toggleRedelegator() {
            if (this.isRunning) {
                return this.setAutodelegatorStatus(false);
            }

            this.$confirm(
                this.$t('redelegator.confirm.text'),
                this.$t('redelegator.confirm.title'), {
                    confirmButtonText: this.$t('redelegator.confirm.ok'),
                    cancelButtonText: this.$t('redelegator.confirm.cancel'),
            }).then(() => {
                this.setAutodelegatorStatus(true);
            }).catch(() => {
                console.info('Cancelled');
            });
        },

        estimateCommission() {
            Minter.estimateDelegateTxCommission().then(fee => this.commission = fee);
        },

        reDelegate() {
            const { floor, percent } = this.settings;
            const stake = new Big(this.$store.getters.balanceBits(defaultCoin))
                .times(percent / 100)
                .minus(this.commission)
                .div(10 ** 18);

            if (stake.lte(floor)) {
                return console.info(`Skipping this turn: ${stake} of ${floor} available`);
            }

            Minter.delegate(null, {
                stake: stake.toString(),
                publicKey: this.validatorPubkey,
                coin: defaultCoin,
            });
        },
    },

    components: {
        AutodelegatorSettings,
    },
};
</script>

<template>
    <el-dialog class="wallet-dialog el-dialog--no-pad"
        v-bind:visible.sync="dialogVisible"
        v-bind:show-close="false">
        <header slot="title">
            <el-tabs v-model="activeName" v-on:tab-click="handleTabClick">
                <el-tab-pane v-bind:label="$t('login.signin')" name="signin">&nbsp;</el-tab-pane>
                <el-tab-pane v-bind:label="$t('login.signup')" name="signup">&nbsp;</el-tab-pane>
            </el-tabs>
        </header>
        <section class="active-view">
            <el-form class="active-view__body" label-position="top" v-loading="isWorking">
                <el-form-item
                    v-bind:label="walletDialogSeedLabel"
                    v-bind:class="{ 'is-error': wallet.error }">
                    <el-input class="login-form__seedbox" lang="en"
                        placeholder="horse battery house trust sunny pidor nose nation bird utility dial"
                        autocorrect="off" data-gramm="false" autocapitalize="off" spellcheck="false"
                        type="textarea"
                        v-bind:readonly="isCreating"
                        v-model="wallet.seed"
                        v-bind:autosize="!isCreating"
                        v-bind:rows="3"/>
                    <div v-if="wallet.error" class="el-form-item__error wallet-dialog__input-error" v-text="wallet.error"/>
                </el-form-item>
                <el-form-item
                    class="login-form__generated-address"
                    v-bind:label="$t('login.generatedAddress')"
                    v-show="isCreating">
                    <el-input spellcheck="false" readonly v-bind:value="wallet.address">
                        <i v-once slot="suffix"
                            class="login-form__inline-refresh-icon el-input__icon el-icon-refresh-left"
                            v-on:click="handleTabClick"/>
                    </el-input>
                </el-form-item>
                <blockquote class="wallet-dialog__info" v-text="$t('login.disclaimer')"/>
            </el-form>
            <footer class="active-view__footer">
                <el-button type="primary" class="button--fat"
                    v-on:click="confirm()"
                    v-bind:disabled="!canProceed"
                    v-text="walletDialogButtonText"/>
            </footer>
        </section>
    </el-dialog>
</template>

<style lang="scss">
.login-form {
    &__generated-address {
        margin-bottom: 24px;
        .el-form-item__label {
            margin-top: 0;
        }
    }
    &__inline-refresh-icon {
        cursor: pointer;
        &:hover {
            color: $aurora_blue;
        }
    }
    &__seedbox textarea {
        font-family: 'Ubuntu Mono', monospace;
        font-size: 16px;
        height: 90px;
        word-break: normal;
        hyphens: auto;
    }
}
</style>

<script>
import { Buffer } from 'safe-buffer';
import {
    walletFromMnemonic,
    isValidMnemonic,
    walletFromPrivateKey,
    generateWallet,
} from 'minterjs-wallet/dist';

const isValidPrivkey = (string) => string.match(/^[a-f\d]{64}$/) !== null;

export default {
    wallet: null,

    data() {
        return {
            dialogVisible: false,
            isWorking: false,
            activeName: 'signin',
            wallet: {
                seed: '',
                address: null,
                error: null,
            },
        };
    },

    watch: {
        'wallet.seed'(input) {
            if (! input || this.isCreating) return;
            this.wallet.error = !isValidPrivkey(input) && !isValidMnemonic(input)
                ? this.$t('login.errorInvalidSeed')
                : null;
        },

        dialogVisible(visible) {
            if (! visible) {
                this.activeName = 'signin';
                this.isWorking = false;
                this.wallet.error = null;
                this.wallet.seed = '';
                this.wallet.address = '';
                this.$options.wallet = null;
            }
        },
    },

    computed: {
        isCreating() {
            return this.activeName === 'signup';
        },

        canProceed() {
            return this.wallet.seed.length > 0 && !this.wallet.error;
        },

        walletDialogSeedLabel() {
            return this.isCreating
                ? this.$t('login.generatedSeed')
                : this.$t('login.userSeedOrPk');
        },

        walletDialogButtonText() {
            if (this.isCreating) {
                return this.$t('login.button.createNew');
            }

            if (this.wallet.error || this.wallet.seed.length == 0) {
                return this.$t('login.button.addWallet');
            }

            return isValidPrivkey(this.wallet.seed)
                ? this.$t('login.button.addViaPk')
                : this.$t('login.button.addViaSeed');
        },
    },

    created() {
        this.$bus.$on('modalWalletAdd:show', () => this.dialogVisible = true);
        this.$bus.$on('modalWalletAdd:hide', () => this.dialogVisible = false);
    },

    beforeDestroy() {
        this.$bus.$off('modalWalletAdd:show');
        this.$bus.$off('modalWalletAdd:hide');
    },

    methods: {
        handleTabClick() {
            if (this.isCreating) {
                this.isWorking = true;
                const timer = setTimeout(() => this.generateRandomWallet(), 150);
            }
        },

        generateRandomWallet() {
            const wallet = generateWallet();

            this.wallet.error = null;
            this.wallet.seed = wallet.getMnemonic();
            this.wallet.address = wallet.getAddressString();

            this.$options.wallet = wallet;

            this.$nextTick(() => this.isWorking = false);
        },

        generateWalletFromPkey() {
            try {
                const key = Buffer.from(this.wallet.seed, 'hex');
                return walletFromPrivateKey(key);

            } catch (error) {
                this.wallet.error = error.message;
            }

            return null;
        },

        generateWalletFromInput() {
            const input = this.wallet.seed;

            return isValidPrivkey(input)
                ? this.generateWalletFromPkey()
                : walletFromMnemonic(input);
        },

        confirm() {
            if (! this.isCreating && this.canProceed) {
                this.$options.wallet = this.generateWalletFromInput();
            }

            this.$emit('walletCreated', this.$options.wallet);
        },
    },
};
</script>

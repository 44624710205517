<template>
    <section>
        <main class="active-view__body">
            <el-form label-position="top" class="confirmation" v-loading="isLoading">
                <el-form-item v-bind:label="$t('coinsModal.buy.confirmation.amountLabel')">
                    <div class="confirmation__input">
                        <div v-text="buyAmountReadable"/>
                    </div>
                </el-form-item>
                <el-form-item v-bind:label="$t('coinsModal.buy.form.approximateTotal')">
                    <div class="confirmation__input">
                        <div v-text="totalGetReadable"/>
                    </div>
                </el-form-item>
                <el-form-item v-bind:label="$t('common.fee') + ':'">
                    <div class="confirmation__input">
                        <div v-text="commissionReadable"/>
                    </div>
                </el-form-item>

                <div class="confirmation__disclaimer" v-text="$t('coinsModal.buy.confirmation.disclaimer')"/>

                <div class="confirmation__disclaimer confirmation__disclaimer--error" v-if="error" v-text="error"/>
            </el-form>
        </main>

        <footer class="active-view__footer d8-modal-footer">
            <el-button v-if="!error" class="button--fat" type="primary"
                key="confirm_button"
                v-on:click="$emit('confirm')">
                {{$t('common.confirm')}}
            </el-button>
            <el-button v-else class="button--fat" v-on:click="$emit('back')">
                {{$t('common.back')}}
            </el-button>
        </footer>
    </section>
</template>

<script>
export default {
    props: ['isLoading', 'valueToBuy', 'coinToBuy', 'coinToSell', 'gasCoin', 'commission', 'approximateTotal', 'error'],

    computed: {
        commissionReadable() {
            return (this.commission / 10 ** 18) + ' ' + this.gasCoin.symbol;
        },

        buyAmountReadable() {
            return this.valueToBuy + ' ' + this.coinToBuy.symbol;
        },

        totalGetReadable() {
            return parseFloat(this.approximateTotal.toFixed(4)) + ' ' + this.coinToSell.symbol;
        },
    }
};
</script>

<template>
    <section class="d8wt-settings">
        {{$t('redelegator.textDelegrating')}}
        <el-popover placement="right" width="50" trigger="click">
            <el-input-number
                v-bind:step="5"
                v-bind:max="100"
                v-bind:min="5"
                v-model="percent">
                <template slot="append">%</template>
            </el-input-number>
            <span data-after="%" class="d8wt-popper-trigger" slot="reference" v-text="percent"/>
        </el-popover>,
        {{$t('redelegator.textFloor')}}
        <el-popover placement="right" width="50" trigger="click">
            <el-input-number
                controls-position="right"
                v-bind:precision="2"
                v-bind:step="10"
                v-bind:max="10000"
                v-bind:min="0"
                v-model="floor">
                <template slot="append">BIP</template>
            </el-input-number>
            <span data-after=" BIP" class="d8wt-popper-trigger" slot="reference" v-text="floor"/>
        </el-popover>
    </section>
</template>

<script>
export default {
    computed: {
        percent: {
            get() {
                return this.$store.state.settings.percent;
            },
            set(percent) {
                if (percent != this.percent) {
                    this.$store.commit('updateSettings', { percent });
                }
            },
        },

        floor: {
            get() {
                return this.$store.state.settings.floor;
            },
            set(floor) {
                if (floor != this.floor) {
                    this.$store.commit('updateSettings', { floor });
                }
            },
        },
    },
};
</script>

import { EXPLORER_ACCOUNTS_API_URL } from '~/config';
import { ERROR } from '~/adapters/minter/Constants';

/**
 * @param  {String} coinSymbol
 * @return {String}
 */
export const getCoinIconUrl = function getCoinAvatarUrlFromExplorer(coinSymbol) {
    return `${EXPLORER_ACCOUNTS_API_URL}/avatar/by/coin/${coinSymbol}`;
};

/**
 * @param  {String} address
 * @return {String}
 */
export const getAvatarUrl = function getAddressAvatarUrlFromExplorer(address) {
    return `${EXPLORER_ACCOUNTS_API_URL}/avatar/by/address/${address}`;
};

/**
 * @see https://stackoverflow.com/questions/30106476
 * @param  {String} str
 * @return {String}
 */
export const b64DecodeUnicode = function decodeTransactionPayload(str) {
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
};

/**
 * Считает сумму мультисэнда и основную монету.
 * @param  {Object} options.list
 * @return {Object}
 */
export const parseOutgoingMultisendTx = function({ list }) {
    const coins = {};
    const sumGroupedByCoinId = list.reduce((total, { value, coin }) => {
        const current = total.get(coin.id) || 0;
        total.set(coin.id, current + parseFloat(value));
        // Группируем по ID, но отдельно сохраняем исходный объект монеты:
        coins[coin.id] = coin;
        return total;
    }, new Map);

    const [[coinId, value]] = sumGroupedByCoinId;

    return {
        value,
        list: Object.freeze(list),
        coin: coins[coinId],
        coinCount: sumGroupedByCoinId.size,
    };
};

/**
 * @param  {String} str
 * @return {String}
 */
export const camelToSnake = function convertStringFromCamelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

/**
 * @param  {Object} obj
 * @return {Object}
 */
export const objectKeysToSnake = function convertAllObjectKeysToSnakeCase(obj) {
    const newObj = {};

    Object.entries(obj).forEach(([key, value]) => {
        newObj[camelToSnake(key)] = value;
    });

    return newObj;
};

/**
 * @param  {String} value
 * @return {String}
 */
export const filterNumber = function stripClutterFromNumber(value) {
    return value.toString()
        .replace(',', '')
        .replace(/[^0-9.]/g, '');
};

/**
 * @param  {Number} code
 * @return {String|null}
 */
export const getTranslationKeyByErrorCode = function(code) {
    switch (code) {
        case ERROR.INSUFFICIENT_FUNDS:
            return 'errors.insufficient_funds';
            break;
        case ERROR.INSUFFICIENT_STAKE:
            return 'errors.insufficient_stake';
            break;
        case ERROR.COIN_SUPPLY_OVERFLOW:
            return 'errors.coin_supply_overflow';
            break;
        default:
            return null;
            break;
    }
};

import axios from 'axios';
import { VENDOR_MNS_API_ADDRESS } from '~/config';

const http = axios.create({
    baseURL: VENDOR_MNS_API_ADDRESS,
});

export default {
    resolve(domain) {
        return http.get(`/resolve?domain=${domain}`)
            .then(({ data: { address, publickey, ticker }}) => {
                return { address, publickey, ticker };
            });
    },

    extractAddress(domain) {
        return this.resolve(domain).then(({ address }) => {
            return address
                ? Promise.resolve(address)
                : Promise.reject('This domain does not have an address');
        });
    },
};

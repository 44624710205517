export const dereference = function breakReferences(obj) {
    const newObj = {};

    // TODO: process arrays

    Object.entries(obj).forEach(([ key, value ]) => {
        newObj[key] = value && typeof value === 'object'
            ? dereference(value)
            : value;
    });

    return newObj;
};

export const isCoinObject = function entityIsCoinObject(obj) {
    return typeof obj === 'object'
        && 'id' in obj
        && 'symbol' in obj;
};

export const flattenCoinObjects = function replaceCoinObjectWithCoinId(obj) {
    Object.entries(obj).forEach(([ key, value ]) => {
        if (typeof value !== 'object') {
            return;
        }

        return isCoinObject(value)
            ? obj[key] = parseInt(value.id)
            : flattenCoinObjects(value);
    });
};

<template>
    <el-dialog class="wallet-dialog wallet-dialog--m-fullscreen el-dialog--no-pad"
        v-bind:visible.sync="isVisible"
        v-bind="dialogAttributes">

        <active-view hide-desktop-title
            v-on:back="isVisible = false"
            v-bind:title="$t('txHistory.title')">
            <template v-slot:header>
                <el-tabs v-model="activeName">
                    <el-tab-pane v-bind:label="$t('txHistory.tabTransactions')" name="delegations">&nbsp;</el-tab-pane>
                    <el-tab-pane v-bind:label="$t('txHistory.tabRewards')" name="rewards">&nbsp;</el-tab-pane>
                </el-tabs>
            </template>

            <section class="active-view" v-if="isDeflorated">
                <transactions-history
                    v-bind:transactions="transactions"
                    v-bind:hasMoreHistory="hasMoreHistory"
                    v-bind:isLoading="isLoadingHistory"
                    v-on:loadMore="loadMore"
                    v-show="activeName === 'delegations'"/>
                <rewards-history
                    v-show="activeName === 'rewards'"/>
            </section>

            <section class="active-view" v-else v-once>
                <nav class="tx-history-filter">
                    <span class="tx-history-item__dummy"/>
                </nav>
                <header class="tx-history-separator">
                    <span class="tx-history-item__dummy"/>
                </header>
                <transaction-skeleton v-for="i in 5" v-bind:key="i"/>
            </section>

            <footer class="el-dialog__footer"/>
        </active-view>
    </el-dialog>
</template>

<script>
import RewardsHistory from './TabRewards';
import TransactionsHistory from './TabTransactions';
import mobileDialogMixin from '~/mixins/mobileDialogs';
import TransactionSkeleton from './TransactionSkeleton';
import { getTransactions } from '~/adapters/minter/ExplorerApi';
import { TX_TYPE } from '~/adapters/minter/Constants';
import { mapState } from 'vuex';

const freezeTx = (tx) => {
    Object.freeze(tx);

    if ('data' in tx) {
        Object.freeze(tx.data);
        if ('list' in tx.data) {
            Object.freeze(tx.data.list);
        }
    }

    return tx;
};

export default {
    data() {
        return {
            activeName: 'delegations',
            isDeflorated: false,
            isVisible: false,
            transactions: [],
            latestBlock: 0,
            oldestBlock: 0,
            hasMoreHistory: false,
            isLoadingHistory: false,
        };
    },

    computed: mapState({
        address: state => state.wallet.address,
    }),

    created() {
        this.$bus.$on('modalStats:show', () => this.isVisible = true);
        this.$bus.$on('balanceUpdated', () => this.updateData());

        this.loadHistory().then((meta) => {
            this.hasMoreHistory = meta.current_page != meta.last_page;
        });
    },

    beforeDestroy() {
        this.$bus.$off('modalStats:show');
        this.$bus.$off('balanceUpdated', () => this.updateData());
    },

    watch: {
        // История транзакций загружается при бутстрапе приложения. Поэтому при первом 
        // открытии модального окна появится задержка - компонент сначала отрендерит
        // весь список, а потом откроет модальное окно.
        // Поэтому сначала открываем диалог, а затем рендерим список.
        isVisible(visible) {
            if (visible && !this.isDeflorated) {
                setInterval(() => this.isDeflorated = true, 50);
            }
        },

        transactions(list) {
            return list.length && list[0].height > this.latestBlock
                ? this.handleNewTransactionsBatch()
                : false;
        },
    },

    methods: {
        updateData() {
            const startBlock = this.latestBlock + 1;
            this.loadHistory({ startBlock }).catch(console.error);
        },

        loadMore() {
            const endBlock = this.oldestBlock - 1;
            this.isLoadingHistory = true;
            this.loadHistory({ endBlock })
                .then(meta => this.hasMoreHistory = meta.total > 0)
                .catch(console.error)
                .then(() => this.isLoadingHistory = false);
        },

        loadHistory(params = {}) {
            return getTransactions(this.address, params)
                .then(({ data, meta }) => {
                    const newTxs = data
                        .filter(newTx => !this.transactions.some(item => item.txn == newTx.txn))
                        .map(freezeTx);

                    this.transactions.unshift(...newTxs.filter(tx => tx.height > this.latestBlock));
                    this.transactions.push(...newTxs.filter(tx => tx.height < this.oldestBlock));

                    return Promise.resolve(meta);
                });
        },

        handleNewTransactionsBatch() {
            const txList = this.transactions;
            this.latestBlock = Math.max(this.latestBlock, txList[0].height);
            // Изначально значение oldestBlock = нулю, поэтому Math.min не будет работать -
            // ноль меньше, чем любой полученный блок. Поэтому сначала сравниваем с новым блоком:
            this.oldestBlock = Math.min(
                this.oldestBlock > 0 ? this.oldestBlock : this.latestBlock,
                txList[txList.length - 1].height);

            const lastIncoming = txList.find(tx => {
                return (tx.type == TX_TYPE.MULTISEND || tx.type == TX_TYPE.SEND)
                    && (tx.from === tx.data?.to   // Транзакции, которые юзер отправил сам себе
                    || tx.from !== this.address); // Входящие транзакции
            });

            return lastIncoming
                ? this.$bus.$emit('newIncomingTx', lastIncoming)
                : console.debug('No notifiable transactions');
        },
    },

    mixins: [
        mobileDialogMixin,
    ],

    components: {
        RewardsHistory, TransactionsHistory, TransactionSkeleton,
    },
};
</script>

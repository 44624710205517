export default {
    data() {
        return {
            mobileDialogIsMobile: false,
        };
    },

    created() {
        this.mobileDialogOnResize();
        window.addEventListener('resize', this.mobileDialogOnResize);
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.mobileDialogOnResize);
    },

    computed: {
        dialogAttributes() {
            return {
                fullscreen: this.mobileDialogIsMobile,
                modal: !this.mobileDialogIsMobile,
                showClose: false,
            };
        },
    },

    methods: {
        mobileDialogOnResize() {
            this.mobileDialogIsMobile = window.innerWidth <= 480;
        },
    },
};

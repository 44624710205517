import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import UiBips from './Components/UiBips';
import UiIcon from './Components/UiIcon';
import UiCoinInput from './Components/UiCoinInput';
import UiCoinSelector from './Components/UiCoinSelector';
import AppAutodelegator from './Components/D8/Main';
import PwaInstallDialog from './Components/PwaInstallDialog';
import ActiveView from './Components/D8/ActiveView';
import { registerSentry } from './bootstrap/sentry';
import { registerServices } from './bootstrap/services';
import CurrenciesMixin from './bootstrap/currencies';
import BackgroundMixin from './bootstrap/background';
import VaultLovesVuex from './bootstrap/juicer';
import electron from './bootstrap/electron';
import axios from './bootstrap/http';
import notifications from './bootstrap/notifications';
import store from './store';
import ru from './locales/ru';
import en from './locales/en';
import Big from 'big.js';

Big.PE = 36;
Big.DP = 18;
Big.RM = 2;

const eventBus = new Vue({
    methods: {
        toElectron(event, payload) {
            return this.$emit('_bridge_', { event, payload });
        },
    },
});

Vue.prototype.$bus = eventBus;
Vue.prototype.$http = axios;

Vue.use(ElementUI, { locale });
Vue.use(VueClipboard);
Vue.use(VueI18n);

Vue.component('ui-bips', UiBips);
Vue.component('ui-icon', UiIcon);
Vue.component('ui-coin-selector', UiCoinSelector);
Vue.component('ui-coin-input', UiCoinInput);
Vue.component('active-view', ActiveView);

const i18n = new VueI18n({
    locale: 'ru',
    messages: { ru, en },
});

new Vue({
    i18n, store,
    el: '#app',
    beforeCreate() {
        this.$i18n.locale = this.$store.state.settings.language;

        registerServices(this.$bus);
        registerSentry();

        notifications.init(this);
        electron.init(this);
    },

    beforeDestroy() {
        electron.destroy();
    },

    template: `
        <main>
            <pwa-install-dialog/>
            <el-container>
                <el-main>
                    <div class="d8-container">
                        <app-autodelegator/>
                    </div>
                </el-main>
            </el-container>
        </main>`,

    components: { 
        PwaInstallDialog, AppAutodelegator,
    },

    mixins: [ 
        BackgroundMixin, CurrenciesMixin, VaultLovesVuex,
    ],
});

<template>
    <el-dialog class="wallet-dialog el-dialog--no-pad" top="20px"
        v-bind:visible.sync="isVisible">
        <section class="active-view">
            <el-page-header class="active-view__header"
                v-bind:title="$t('common.back')"
                v-on:back="isVisible = false"
                v-bind:content="$t('receiveModal.title')"/>
            <main class="modal-receive-qr">
                <div class="modal-receive-qr__container">
                    <img v-bind:src="qrCodeUrl" class="modal-receive-qr__img">
                </div>
            </main>
        </section>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            isVisible: false,
        };
    },

    computed: {
        qrCodeUrl() {
            return `https://chart.googleapis.com/chart?chs=29x29&cht=qr&chld=L|0&chl=${this.walletAddress}`;
        },

        walletAddress() {
            return this.$store.state.wallet.address;
        },
    },

    created() {
        this.$bus.$on('modalReceiveCoins:show', () => this.isVisible = true);
    },

    beforeDestroy() {
        this.$bus.$off('modalReceiveCoins:show');
    },
};
</script>

<template>
    <section class="d8-updater">
        <el-button plain class="d8-updater__button"
            v-bind:type="buttonType"
            v-bind:loading="button.busy"
            v-on:click="checkForUpdates()">
            {{button.text}}
        </el-button>
        <div class="d8-updater__status"
            v-bind:class="{ 'd8-updater__status--error': button.error }"
            v-text="button.status"/>
    </section>
</template>

<script>
export default {
    data() {
        return {
            button: {
                text: null,
                busy: false,
                status: null,
                error: false,
            },
        };
    },

    computed: {
        buttonType() {
            return this.button.error ? 'danger' : 'plain';
        },
    },

    created() {
        this.$bus.$on('electron:updateDownloaded', this.onUpdateDownloaded);
        this.$bus.$on('electron:updateChecking', this.onUpdateChecking);
        this.$bus.$on('electron:updateNotAvailable', this.onUpdateNotAvailable);
        this.$bus.$on('electron:updateError', this.onUpdateError);

        this.resetButton();
    },

    beforeDestroy() {
        this.$bus.$off('electron:updateDownloaded', this.onUpdateDownloaded);
        this.$bus.$off('electron:updateChecking', this.onUpdateChecking);
        this.$bus.$off('electron:updateNotAvailable', this.onUpdateNotAvailable);
        this.$bus.$off('electron:updateError', this.onUpdateError);
    },

    methods: {
        checkForUpdates() {
            this.resetButton();
            this.onUpdateChecking();
            this.$bus.toElectron('electron:checkForUpdates');
        },

        onUpdateAvailable() {
            this.resetButton(this.$t('electron.update.available'));
        },

        onUpdateNotAvailable() {
            this.resetButton(this.$t('electron.update.unavailable'));
        },

        onUpdateDownloaded() {
            this.button.busy = false;
            this.button.status = this.$t('electron.update.downloaded');
        },

        onUpdateChecking() {
            this.button.busy = true;
            this.button.text = this.$t('electron.update.checking');
        },

        onUpdateError(error) {
            this.button.busy = false;
            this.button.text = this.$t('electron.update.error');
            this.button.error = error.code;

            console.error(`Обновление не загружено: ${error.code}`);
        },

        resetButton(btnText) {
            const text = btnText || this.$t('electron.update.check');
            Object.assign(this.button, { text, busy: false, error: false, status: '' });
        },
    },
};
</script>

<style lang="scss">
.d8-updater {
    &__button {
        display: block;
        width: 100%;
    }
    &__status {
        display: block;
        margin-top: 8px;
        font-size: 12px;
        text-align: center;
        &--error {
            color: #F56C6C;
        }
    }
}
</style>

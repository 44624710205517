<template functional>
    <svg v-bind:width="props.size" v-bind:height="props.size">
        <use v-bind:xlink:href="`mdi.svg#${props.name}`"/>
    </svg>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
            default: 24,
        },
    },
};
</script>

<template>
    <section>
        <el-page-header class="active-view__header"
            v-bind:title="$t('common.back')"
            v-on:back="$emit('back')"
            v-bind:content="$t('stakeModal.title')"/>

        <div class="d8-stats-list__empty-block" v-if="stakes.length == 0" v-loading="isLoading">
            <span v-text="$t('common.nodata')"/>
        </div>

        <div class="coin-list" style="min-height: unset">
            <div class="coin-list-item"
                v-for="stake in stakes"
                v-bind:key="stake.coin.id">
                <d8-coin v-bind="stake.coin" v-bind:balance="stake.value"/>
                <span class="coin-list-item__kukuruzer"/>
                <el-button class="coin-list-item__action"
                    v-on:click="$emit('selectCoin', stake.coin)"
                    v-bind:title="$t('stakeModal.actionUnbond')">
                    <ui-icon name="open_in_app"/>
                </el-button>
            </div>
        </div>
    </section>
</template>

<script>
import D8Coin from './Coin';

export default {
    computed: {
        isLoading() {
            return this.$store.state.services.stakeUpdater.loading;
        },

        stakes() {
            return this.$store.getters.stakes;
        },
    },

    components: {
        D8Coin,
    },
};
</script>

<template>
    <el-dialog class="wallet-dialog el-dialog--no-pad" top="20px"
        v-bind:visible.sync="isVisible">
        <section v-show="!confirmation" class="active-view">
            <el-page-header class="active-view__header"
                v-bind:title="$t('common.back')"
                v-on:back="isVisible = false"
                v-bind:content="$t('sendModal.title')"/>

            <el-form label-position="top" class="active-view__body">
                <el-form-item
                    v-bind:class="{ 'is-error': !!error }"
                    v-bind:label="$t('sendModal.form.receiver')">
                    <el-autocomplete clearable style="width: 100%"
                        v-bind:loading="true"
                        v-model="receiver"
                        v-bind:placeholder="$t('sendModal.form.receiverPlaceholder')"
                        v-bind:fetch-suggestions="querySearch">
                        <span slot="suffix">
                            <i v-show="isLoadingAddresses" class="el-icon-loading el-input__icon"/>
                        </span>
                        <template slot-scope="{ item }">
                            <span class="ui-looong"
                                v-bind:data-loopa="item.value.substr(0, 36)"
                                v-bind:data-poopa="item.value.substr(36)">
                            </span>
                        </template>
                    </el-autocomplete>
                    <div v-if="error" v-text="error" class="el-form-item__error"/>
                </el-form-item>

                <el-form-item v-bind:label="$t('sendModal.form.amount')">
                    <ui-coin-input show-max
                        v-bind:gasCoin="tx.gasCoin"
                        v-bind:value="txData.value"
                        v-bind:coin="txData.coin"
                        v-bind:commission="commission"
                        v-on:valueUpdated="(value) => txData.value = value"
                        v-on:coinUpdated="(coin) => txData.coin = coin"/>
                </el-form-item>

                <section class="d8-action-modal__extras" v-show="advancedVisible">
                    <common-tx-params v-bind="tx" v-on:update="updateTxParams"/>
                </section>
            </el-form>

            <footer class="active-view__footer d8-modal-footer">
                <el-button class="button--fat" type="primary"
                    v-bind:disabled="!canProceed"
                    v-on:click="confirmation = true">
                    {{$t('sendModal.form.submit')}}
                </el-button>
                <el-button type="text" class="d8-modal-footer__settings"
                    v-on:click="advancedVisible = !advancedVisible">
                    <ui-icon name="settings"/>
                </el-button>
            </footer>
        </section>

        <send-confirmation v-if="confirmation"
            v-on:back="confirmation = false"
            v-on:confirm="send()"
            v-bind:error="error"
            v-bind:isLoading="isLoading"
            v-bind:gasCoin="tx.gasCoin"
            v-bind:commission="commission"
            v-bind="txData"/>
    </el-dialog>
</template>

<script>
import { txSchema, sendTxDataSchema } from '~/adapters/minter/Schema';
import Minter from '~/adapters/minter';
import MNS from '~/adapters/mns';
import { filterNumber, getTranslationKeyByErrorCode } from '~/helpers';
import debounce from 'lodash.debounce';
import CommonTxParams from './ModalPartialTxCommon';
import SendConfirmation from './ModalSendCoinsConfirmation';
import { mapState } from 'vuex';

const createFilter = (queryString) => {
    return (item) => {
        return (item.address.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
    };
};

export default {
    data() {
        return {
            isVisible: false,
            isLoading: false,
            isLoadingAddresses: false,
            advancedVisible: false,
            confirmation: false,
            receiver: '',
            commission: 0,
            error: null,
            tx: {},
            txData: {},
        };
    },

    computed: {
        ...mapState({
            address: state => state.wallet.address,
            recentAddresses: state => state.wallet.phonebook.recentAddresses,
        }),

        validDomain() {
            return !!this.receiver.match(/[\w\.]{3,}\.[a-z]{2,}/i);
        },

        validAddress() {
            return !!this.receiver.match(/Mx[\da-f]{40}/i);
        },

        canProceed() {
            return !this.error
                && !this.isLoadingAddresses
                && this.txData.value > 0
                && (this.validDomain || this.validAddress);
        },
    },

    created() {
        this.$bus.$on('modalSendCoins:show', () => this.isVisible = true);
        this.resetTxFields();
    },

    mounted() {
        this.$watch(vm => [vm.txData.coin.id, vm.tx.gasCoin.id, vm.tx.payload], debounce(function () {
            this.updateEstimatedCommission();
        }, 450), {
            immediate: true,
        });
    },

    beforeDestroy() {
        this.$bus.$off('modalSendCoins:show');
    },

    watch: {
        receiver: debounce(function (value) {
            if (this.validAddress) {
                this.error = null;
                this.txData.to = value;
                return;
            }

            if (this.validDomain) {
                return this.resolveDomain();
            }

            // If input is not a domain and not an address, reset inner
            // tx.to value:
            this.txData.to = null;

            // Allow the input to be empty. If it's not empty and value
            // is neither a domain nor an address, show error:
            this.error = value.length > 0
                ? this.$t('sendModal.errors.invalidAddress')
                : null;
        }, 500),

        isVisible(visible) {
            if (! visible) {
                this.isLoading = false;
                this.advancedVisible = false;
                this.isLoadingAddresses = false;
                this.confirmation = false;
                this.error = null;
                this.receiver = '';
            } else {
                this.resetTxFields();
            }
        },

        'txData.value': {
            handler(newValue) {
                this.txData.value = filterNumber(newValue);
            },
        },

        confirmation(isVisible) {
            if (! isVisible) this.error = null;
        },
    },

    methods: {
        querySearch (queryString, cb) {
            const results = queryString
                ? this.recentAddresses.filter(createFilter(queryString))
                : this.recentAddresses;
            cb(results.map(({ address: value }) => ({ value })));
        },

        resolveDomain() {
            this.isLoadingAddresses = true;
            this.error = null;

            MNS.extractAddress(this.receiver)
                .then((address) => this.txData.to = address)
                .catch(() => this.error = this.$t('sendModal.errors.unresolvableDomain'))
                .then(() => this.isLoadingAddresses = false);
        },

        updateEstimatedCommission() {
            Minter.estimateSendTxCommission(this.tx).then(fee => this.commission = fee);
        },

        send() {
            this.isLoading = true;
            Minter.send(this.tx, this.txData)
                .then(() => {
                    this.$message.success(this.$t('txCommon.messages.success'));
                    this.isVisible = false;
                })
                .catch(this.handleSendError)
                .then(() => this.isLoading = false);
        },

        handleSendError(error) {
            const translationKey = getTranslationKeyByErrorCode(error.code);
            this.error = translationKey
                ? this.$t(translationKey)
                : error.message || error;
        },

        updateTxParams(params) {
            Object.assign(this.tx, params);
        },

        resetTxFields() {
            this.tx = Object.assign({}, txSchema);
            this.txData = Object.assign({}, sendTxDataSchema);
        },
    },

    components: {
        SendConfirmation,
        CommonTxParams,
    },
};
</script>

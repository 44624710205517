<template>
    <el-dialog class="wallet-dialog wallet-dialog--m-fullscreen el-dialog--no-pad"
        v-bind:visible.sync="isVisible"
        v-bind="dialogAttributes">
        <active-view v-bind:title="$t('settings.title')" v-on:back="isVisible = false">
            <section class="active-view">
                <el-form label-position="top" class="active-view__body">
                    <el-form-item v-bind:label="$t('settings.altCurrency')">
                        <el-select v-model="defaultCurrency" placeholder="USD" class="settings-select">
                            <el-option v-for="item in currencies"
                                v-bind:key="item.value"
                                v-bind:label="item.label"
                                v-bind:value="item.value">
                                <span style="float: left" v-text="item.label"/>
                                <span style="float: right; color: #8492a6;" v-text="item.value"/>
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item v-bind:label="$t('settings.language')">
                        <el-select v-model="language" placeholder="Select language" class="settings-select">
                            <el-option v-for="lang in languages"
                                v-bind:key="lang.value"
                                v-bind:label="lang.label"
                                v-bind:value="lang.value"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item v-if="isElectron" v-bind:label="$t('settings.app')">
                        <d8-updater/>
                    </el-form-item>
                </el-form>

                <div class="settings-item" v-if="notificationsSupported && notificationsAllowed">
                    <ui-icon name="notification"/>
                    <el-switch class="settins-inline-switch"
                        v-model="showNotifications"
                        v-bind:disabled="!notificationsAllowed"
                        v-bind:active-text="$t('settings.showNotifications')"/>
                </div>

                <div class="settings-item">
                    <ui-icon name="bug_report"/>
                    <el-switch v-model="reportErrors"
                        class="settins-inline-switch"
                        v-bind:active-text="$t('settings.reportErrors')"/>
                </div>

                <a class="settings-item" v-if="showChromeButton" id="install_pwa">
                    <ui-icon name="laptop_mac"/>
                    <div class="settings-item__content" v-text="$t('settings.installToChrome')"/>
                    <i class="settings-item__caret el-icon-arrow-right"/>
                </a>

                <a class="settings-item" href="tg://resolve?domain=MinterOne">
                    <ui-icon name="send"/>
                    <div class="settings-item__content" v-text="$t('settings.buttons.telegram')"/>
                    <i class="settings-item__caret el-icon-arrow-right"/>
                </a>

                <a class="settings-item" href="tg://resolve?domain=adiutor">
                    <ui-icon name="headset_mic"/>
                    <div class="settings-item__content" v-text="$t('settings.buttons.support')"/>
                    <i class="settings-item__caret el-icon-arrow-right"/>
                </a>

                <div class="settings-item">
                    <ui-icon name="exit_to_app"/>
                    <div class="settings-item__content" v-text="$t('settings.appVersion')"/>
                    <span class="settings-item__label" v-text="appVersion" v-bind:title="appEnv"/>
                </div>
            </section>
        </active-view>
    </el-dialog>
</template>

<script>
import { APP_VERSION, APP_ENV, APP_IS_ELECTRON } from '~/config';
import { mapState } from 'vuex';
import D8Updater from './Updater';
import mobileDialogMixin from '~/mixins/mobileDialogs';

export default {
    data() {
        return {
            isVisible: false,
        };
    },

    computed: {
        appEnv() { return APP_ENV; },
        appVersion() { return APP_VERSION; },
        isElectron() { return APP_IS_ELECTRON; },

        ...mapState({
            exchangeRates: 'exchangeRates',
            notificationsSupported: state => state.services.notifications.supported,
            notificationsAllowed: state => state.services.notifications.allowed,
            showChromeButton: state => state.settings.pwaDismissed,
        }),

        languages() {
            return [
                { value:'en', label: 'English' },
                { value:'ru', label: 'Русский' },
            ];
        },

        defaultCurrency: {
            get() {
                return this.$store.state.settings.currency;
            },
            set(currency) {
                this.$store.commit('updateSettings', { currency });
            },
        },

        language: {
            get() {
                return this.$store.state.settings.language;
            },
            set(language) {
                this.$store.commit('updateSettings', { language });
            },
        },

        reportErrors: {
            get() {
                return this.$store.state.settings.reportErrors;
            },
            set(reportErrors) {
                this.$store.commit('updateSettings', { reportErrors });
            },
        },

        showNotifications: {
            get() {
                return this.$store.state.settings.notifications; 
            },
            set(notifications) {
                this.$store.commit('updateSettings', { notifications });
            },
        },

        currencies() {
            return this.exchangeRates.map(({ symbol }) => {
                return { label: this.$t(`settings.currencies.${symbol.toLowerCase()}`), value: symbol };
            });
        },
    },

    created() {
        this.$bus.$on('modalSettings:show', () => this.isVisible = true);
        this.$bus.$on('modalCheckUpdates:show', () => this.isVisible = true);
    },

    mixins: [ mobileDialogMixin ],

    components: {
        D8Updater,
    },
};
</script>

<style lang="scss">
.settings-item {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border-top: 1px solid #fafafa;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    color: #555;
    position: relative;
    svg {
        fill: #cdcad8;
        margin-right: 20px;
        flex-shrink: 0;
    }
    &__content {
        flex-grow: 1;
    }
    &__caret {
        color: #8B8B8B;
    }
    &__label {
        background: #ECEBF2;
        line-height: 1;
        padding: 6px 8px;
        border-radius: 4px;
        margin: -4px 0;
        color: #7A7A7A;
    }
    &.disabled {
        padding-bottom: 32px;
        padding-top: 12px;
        background: #f8f8f8;
        svg {
            margin-bottom: -10px;
            opacity: .5;
        }
        .el-switch__label {
            font-weight: 400;
            color: #888;
        }
    }
}
a.settings-item {
    position: relative;
    cursor: pointer;
    &:hover {
        color: #444;
        border-color: #eee;
        background: #fafafa;
    }
}
.settins-inline-switch {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    margin: 0;
    .el-switch__label {
        font-weight: 400;
        color: #555;
        margin: 0;
        &.is-active {
            color: #555;
        }
    }
}
.settings-select {
    width: 100%;
}
</style>

const registerServiceWorker = () => {
    if (!navigator.serviceWorker || 'electronBridge' in window) {
        return;
    }

    navigator.serviceWorker.register('/service_worker.js').then((sw) => {
        console.debug(`%cSW registration successful on ${sw.scope}`, 'color: green; font-weight: bolder');
    });
};

const registerEventHandlers = ($bus) => {
    const info = (string) => {
        const frags = string.split('->');
        console.info(`%c${frags[0]} ->%c ${frags[1]}`, 'color: gray', 'color: unset; font-weight: bolder');
    };

    $bus.$on('DelegateTransaction', ({ txParams: { stake }}) => {
        info(`MainAddress -> DELEGATE(${stake})`);
    });

    $bus.$on('UnbondTransaction', ({ txParams: { stake, coinSymbol }}) => {
        info(`MainAddress -> UNBOND(${stake} ${coinSymbol})`);
    });

    $bus.$on('balanceUpdated', ({ bipsChanged, address, balance }) => {
        bipsChanged
            ? info(`${address} -> BALANCE: ${balance}`)
            : info(`${address} -> COIN BALANCE CHANGED`);
    });

    $bus.$on('PWAInstallReady', (event) => {
        console.debug('%cThis app can now be installed as PWA', 'color: green; font-weight: bolder');
    });

    $bus.$on('PWAInstallFinished', (success) => {
        console.debug('PWA installation completed', success);
    });
};

const scaffoldPWA = ($bus) => {
    window.addEventListener('beforeinstallprompt', (PWAInstaller) => {
        PWAInstaller.preventDefault();
        $bus.$emit('PWAInstallReady', PWAInstaller);

        window.addEventListener('click', (event) => {
            if (event.target.id && event.target.id == 'install_pwa') {
                PWAInstaller.prompt();
                PWAInstaller.userChoice.then((choiceResult) => {
                    $bus.$emit('PWAInstallFinished', choiceResult.outcome === 'accepted');
                });
            }
        });
    });  
};

const addBodyClasses = () => {
    if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        document.body.classList.add('is-ios');
    }
};

const registerServices = ($bus) => {
    registerServiceWorker();
    registerEventHandlers($bus);
    scaffoldPWA($bus);
    addBodyClasses();
};

export {
    registerServices,
};

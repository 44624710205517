<template>
    <div class="active-view">
        <div class="d8-stats-list__empty-block" v-if="coins.length == 0">
            <span v-text="$t('common.nodata')"/>
        </div>

        <div class="coin-list">
            <div class="coin-list-item" v-for="coin in coins" v-bind:key="coin.id">
                <d8-coin v-bind="coin" v-bind:balance="coin.balance"/>
                <span class="coin-list-item__kukuruzer"/>
                <div class="coin-list-item__button-group">
                    <el-button class="coin-list-item__action"
                        v-if="!coin.isPoolToken"
                        v-bind:title="$t('coinsModal.buy.search.actionPurchase')"
                        v-on:click.stop="$bus.$emit('modalMyCoins:buyCoin', coin)">
                        <ui-icon name="add_shopping_cart" />
                    </el-button>
                    <el-button class="coin-list-item__action"
                        v-if="coin.bits > 0"
                        v-on:click.stop="$bus.$emit('modalMyCoins:sellCoin', coin)">
                        <ui-icon name="sync"/>
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import D8Coin from '~/Components/D8/Coin';
import { mapGetters } from 'vuex';

export default {
    computed: mapGetters({
        coins: 'balances',
    }),

    components: {
        D8Coin,
    },
};
</script>

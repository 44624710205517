<template>
    <dl class="tx-details">
        <div class="tx-details__group">
            <dt class="tx-details__title" v-text="$t('txHistory.info.date')"/>
            <dd class="tx-details__value">
                <time v-text="time"/>
            </dd>
        </div>

        <div class="tx-details__group" v-if="displayedAmount">
            <dt class="tx-details__title" v-text="$t('txHistory.info.amount')"/>
            <dd class="tx-details__value" v-once>
                {{displayedAmount}}
                {{displayedCoin.symbol}}<span class="tx-details__value__coin-id">#{{displayedCoin.id}}</span>
                <span v-if="type == 13 && !isIncoming && txData.coinCount > 1"
                    class="tx-details__muted"
                    v-text="$t('txHistory.info.moreCoins', [ txData.coinCount - 1 ])"/>
            </dd>
        </div>

        <div class="tx-details__group" v-if="showSender">
            <dt class="tx-details__title" v-text="$t('txHistory.info.sender')"/>
            <dd class="tx-details__value">
                <span v-if="!isIncoming" v-text="$t('txHistory.info.you')"/>
                <span v-else v-text="sender"/>
            </dd>
        </div>

        <div class="tx-details__group" v-if="receiver && !isIncoming">
            <dt class="tx-details__title" v-text="$t('txHistory.info.receiver')"/>
            <dd class="tx-details__value" v-once v-text="receiver"/>
        </div>

        <div class="tx-details__group" v-if="type == 2 || type == 3 || type == 4 || type == 23 || type == 24">
            <dt class="tx-details__title" v-text="$t('txHistory.info.coinSold')"/>
            <dd class="tx-details__value" v-once>
                {{parseFloat(txData.value_to_sell)}}
                {{txData.coin_to_sell.symbol}}<span class="tx-details__value__coin-id">#{{txData.coin_to_sell.id}}</span>
            </dd>

            <dt class="tx-details__title" v-text="$t('txHistory.info.coinReceived')"/>
            <dd class="tx-details__value" v-once>
                {{parseFloat(txData.value_to_buy)}}
                {{txData.coin_to_buy.symbol}}<span class="tx-details__value__coin-id">#{{txData.coin_to_buy.id}}</span>
            </dd>
        </div>

        <div class="tx-details__group" v-if="type == 5">
            <dt class="tx-details__title" v-text="$t('txHistory.info.coinName')"/>
            <dd class="tx-details__value" v-once v-text="txData.name"/>

            <dt class="tx-details__title" v-text="$t('txHistory.info.coinSymbol')"/>
            <dd class="tx-details__value" v-once v-text="txData.symbol"/>

            <dt class="tx-details__title" v-text="$t('txHistory.info.coinInitialAmount')"/>
            <dd class="tx-details__value" v-once>
                {{parseFloat(txData.initial_amount)}}
            </dd>

            <dt class="tx-details__title" v-text="$t('txHistory.info.coinInitialReserve')"/>
            <dd class="tx-details__value" v-once>
                {{parseFloat(txData.initial_reserve)}}
            </dd>

            <dt class="tx-details__title" v-text="$t('txHistory.info.coinCRR')"/>
            <dd class="tx-details__value" v-once>
                {{parseFloat(txData.constant_reserve_ratio)}}%
            </dd>
        </div>

        <!-- ADD_LIQUIDITY 0x15, REMOVE_LIQUIDITY 0x16 -->
        <div class="tx-details__group" v-if="type == 21 || type == 22">
            <dt class="tx-details__title" v-text="$t('txHistory.info.addedLiquidity')"/>
            <dd class="tx-details__value" v-once>
                <div>
                    {{parseFloat(txData.volume0)}}
                    {{txData.coin0.symbol}}<span class="tx-details__value__coin-id">#{{txData.coin0.id}}</span>
                </div>
                <div>
                    {{parseFloat(txData.volume1)}}
                    {{txData.coin1.symbol}}<span class="tx-details__value__coin-id">#{{txData.coin1.id}}</span>
                </div>
            </dd>

            <dt class="tx-details__title" v-text="$t('txHistory.info.lpAmount')"/>
            <dd class="tx-details__value" v-once>
                {{parseFloat(txData.liquidity)}}
                {{txData.pool_token.symbol}}<span class="tx-details__value__coin-id">#{{txData.pool_token.id}}</span>
            </dd>
        </div>

        <div class="tx-details__group" v-if="decodedComment">
            <dt class="tx-details__title" v-text="$t('txHistory.info.payload')"/>
            <dd v-once class="tx-details__value" v-text="decodedComment"/>
        </div>

        <div class="tx-details__group">
            <dt class="tx-details__title" v-text="$t('txHistory.info.hash')"/>
            <dd class="tx-details__value" v-once>
                <a class="tx-details__link"
                    target="_blank"
                    rel="noreferrer nofollow"
                    v-bind:href="`${explorerUrl}/transactions/${hash}`"
                    v-text="hash"/>
            </dd>
        </div>
    </dl>
</template>

<script>
import { EXPLORER_WEB_URL } from '~/config';
import { b64DecodeUnicode, parseOutgoingMultisendTx } from '~/helpers';
import { TX_TYPE } from '~/adapters/minter/Constants';

export default {
    props: {
        type: Number,
        hash: String,
        height: Number,
        txn: Number,
        nonce: Number,
        fee: String,
        payload: String,
        timestamp: String,
        from: String,
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        address() {
            return this.$store.state.wallet.address;
        },

        displayedAmount() {
            const shortened = this.txData.value
                || this.txData.stake
                || (this.txData.check && this.txData.check.value);

            return parseFloat(shortened);
        },

        displayedCoin() {
            return (this.txData.check && this.txData.check.coin)
                || this.txData.coin;
        },

        decodedComment() {
            const base64 = (this.txData.check && this.txData.check.payload) || this.payload;

            return base64 ? b64DecodeUnicode(base64) : null;
        },

        time() {
            return new Date(this.timestamp).toLocaleString(this.$i18n.locale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            });
        },

        explorerUrl() {
            return EXPLORER_WEB_URL;
        },

        txData() {
            if (this.type == TX_TYPE.MULTISEND) {
                return this.isIncoming
                    ? this.data.list.find(tx => tx.to == this.address)
                    : parseOutgoingMultisendTx(this.data);
            }

            return this.data;
        },

        receiver() {
            return this.txData.pub_key || this.txData.to;
        },

        sender() {
            return 'check' in this.txData ? this.txData.check.sender : this.from;
        },

        isIncoming() {
            return this.from != this.address;
        },

        showSender() {
            return [1, 7, 9, 13].indexOf(this.type) > -1;
        },
    },
};
</script>

<style lang="scss">
.tx-details {
    padding: 12px 14px 4px 16px;
    background: #f6f7fb;
    border-top: 1px solid rgba(0,0,0,0.1);
    margin: 0;
    appearance: none;
    &__title {
        color: #909dbc;
        font-weight: 400;
        padding: 0;
        line-height: 18px;
        &::after {
            content: ':';
        }
    }
    &__value {
        font-weight: 500;
        margin: 0 0 12px;
        font-size: 14px;
        color: #3c465d;
        &__coin-id {
            color: #BBBBCC;
        }
    }
    &__muted {
        color: #939aab;
        font-size: 12px;
        line-height: 1;
        font-weight: normal;
    }
    &__link {
        color: $aurora_blue;
        text-decoration: none;
        border-bottom: 1px solid;
        &:hover {
            border-bottom: none;
        }
    }
}
</style>

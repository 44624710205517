export const defaultCoin = Object.freeze({
    id: 0,
    symbol: 'BIP',
});

export const txSchema = Object.freeze({
    chainId: 1,
    payload: '',
    gasCoin: defaultCoin,
});

export const sendTxDataSchema = Object.freeze({
    to: '',
    value: 0,
    coin: defaultCoin,
});

export const delegateTxDataSchema = Object.freeze({
    publicKey: '',
    coin: defaultCoin,
    stake: 0,
});

export const unbondTxDataSchema = Object.freeze({
    publicKey: '',
    coin: defaultCoin,
    stake: 0,
});

export const sellTxDataSchema = Object.freeze({
    coinToSell: defaultCoin,
    coinToBuy: defaultCoin,
    valueToSell: 0,
});

export const buyTxDataSchema = Object.freeze({
    coinToSell: defaultCoin,
    coinToBuy: defaultCoin,
    valueToBuy: 0,
});

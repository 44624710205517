<template>
    <section class="active-view">
        <header class="coin-list-search">
            <el-input clearable v-model="search"
                v-bind:suffix-icon="searchSuffixIcon"
                v-bind:placeholder="$t('coinsModal.buy.search.placeholder')">
                <ui-icon v-once slot="prefix" name="search"/>
            </el-input>
        </header>

        <div class="d8-stats-list__empty-block" v-if="search.length < 2 || results.length == 0">
            <span v-if="search.length < 2" v-text="$t('coinsModal.buy.search.tip')"/>
            <span v-else v-text="$t('common.nodata')"/>
        </div>
        
        <div class="coin-list" v-show="search.length >= 2 && results.length > 0">
            <div class="coin-list-item"
                v-for="coin in results"
                v-bind:key="coin.symbol">
                <d8-coin show-buy-price
                    v-bind:reserveBalance="coin.reserve_balance"
                    v-bind="coin"/>
                <span v-once class="coin-list-item__kukuruzer"/>
                <el-button class="coin-list-item__action"
                    v-bind:title="$t('coinsModal.buy.search.actionPurchase')"
                    v-on:click.stop="$bus.$emit('modalMyCoins:buyCoin', coin)">
                    <ui-icon name="add_shopping_cart"/>
                </el-button>
            </div>
        </div>
    </section>
</template>

<script>
import { searchCoins } from '~/adapters/minter/ExplorerApi';
import debounce from 'lodash.debounce';
import D8Coin from '~/Components/D8/Coin';

export default {
    data() {
        return {
            search: '',
            isLoading: false,
            results: [],
        };
    },

    computed: {
        searchSuffixIcon() {
            return this.isLoading ? 'el-icon-loading' : '';
        },
    },

    watch: {
        search: debounce(function () {
            this.searchCoin();
        }, 350),
    },

    mounted() {
        this.searchCoin();
    },

    methods: {
        searchCoin() {
            if (this.search.length < 2) {
                return this.results = [];
            }

            this.isLoading = true;
            searchCoins(this.search).then(({ data }) => {
                this.results = data.slice(0, 10);
                this.isLoading = false;
            });
        },
    },

    components: {
        D8Coin,
    },
};
</script>

<template>
    <span v-clipboard:copy="copy" v-clipboard:success="addressCopied">
        <ui-icon name="copy"/> {{displayedText}}
    </span>
</template>

<script>
export default {
    props: {
        copy: {
            type: String,
            required: true,
        },
        text: {
            type: String,
        },
    },

    data() {
        return {
            buttonText: '',
        };
    },

    computed: {
        displayedText() {
            return this.buttonText || this.text || this.$t('common.copyButton.text');
        },
    },

    methods: {
        addressCopied() {
            this.buttonText = this.$t('common.copyButton.success');

            setTimeout(() => {
                this.buttonText = '';
            }, 2000);
        },
    },
};
</script>

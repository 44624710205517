import { MinterAPI } from '.';
import { defaultCoin } from '~/adapters/minter/Schema';
import { isCoinObject } from '~/adapters/minter/utils';
import { objectKeysToSnake } from '~/helpers';

/**
 * Проверяет, является ли тикер идентификатором токена пула.
 * @param  {String} input
 * @return {Boolean}
 */
const symbolIsPoolToken = input => input.startsWith('LP-');

/**
 * Добавляет и очищает поля, полученные от методов API coin_info.
 * @param  {Object} options.data Ответ от API
 * @return {Object}
 */
const prepareCoinData = function addMetaDataToCoinObject({ data }) {
    return Object.freeze({ ...data,
        isPoolToken: symbolIsPoolToken(data.symbol),
        isToken: parseInt(data.crr) === 0,
        crr: parseInt(data.crr),
    });
};

/**
 * Получает баланс адреса. Добавляет к каждой монете мета-информацию.
 * @param  {String} address
 * @return {Promise<Object>}
 */
export const getBalance = function(address) {
    return MinterAPI.get(`/address/${address}`).then(({ data }) => {
        data.balance.forEach(balance => balance.coin.isPoolToken = symbolIsPoolToken(balance.coin.symbol));
        return data;
    });
};

/**
 * Подсчитывает примерную стоимость продажи монеты. Принимает как айди, так и тикеры.
 * @param  {Object} options.rawParams
 * @return {Promise<Object>}
 */
export const estimateCoinSell = function({ ...rawParams }) {
    const params = objectKeysToSnake(rawParams);

    if (isCoinObject(params.coin_to_sell)) {
        params.coin_id_to_sell = params.coin_to_sell.id;
        delete params.coin_to_sell;
    }

    if (isCoinObject(params.coin_to_buy)) {
        params.coin_id_to_buy = params.coin_to_buy.id;
        delete params.coin_to_buy;
    }

    return MinterAPI.get('/estimate_coin_sell', { params }).then(({ data }) => data);
};

/**
 * Стоимость монеты в BIP при её продаже.
 */
export const estimateCoinPrice = function(coinToSell) {
    return estimateCoinSell({ coinToSell,
        coinToBuy: defaultCoin,
        valueToSell: 10 ** 18,
    });
};

/**
 * Возвращает информацию о монете. Поддерживает разные типы аргументов.
 * @param  {Object|String|Number}  coin
 * @return {Promise<Object>}
 */
export const getCoinInfo = function(input) {
    if (isCoinObject(input)) {
        return getCoinInfoById(input.id);
    }

    return typeof input === 'number'
        ? getCoinInfoById(input)
        : getCoinInfoByTicker(input);
};

/**
 * @param  {Number} id
 * @return {Promise<Object>}
 */
export const getCoinInfoById = function(id) {
    return MinterAPI.get(`/coin_info_by_id/${id}`).then(prepareCoinData);
};

/**
 * @param  {String} ticker
 * @return {Promise<Object>}
 */
export const getCoinInfoByTicker = function(ticker) {
    return MinterAPI.get(`/coin_info/${ticker}`).then(prepareCoinData);
};

export default {
    getBalance,
    getCoinInfo,
    getCoinInfoById,
    estimateCoinSell,
    estimateCoinPrice,
};

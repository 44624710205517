export default {
    login: {
        mainButton: 'Login with wallet',
        signin: 'Sign in',
        signup: 'Create wallet',
        userSeedOrPk: 'Seed phrase or private key:',
        generatedSeed: 'Generated seed:',
        generatedAddress: 'Generated address:',
        disclaimer: 'Your private key is encrypted and stored locally (only on your computer).',
        errorInvalidSeed: 'Invalid seed',
        button: {
            createNew: 'Create wallet',
            addWallet: 'Login',
            addViaPk: 'Login via private key',
            addViaSeed: 'Login via seed',
        },
    },

    wallet: {
        copyAddressButton: 'Copy address',
        openInExplorer: 'View in Explorer',
        receiveCoins: 'Receive funds',
        sendCoins: 'Send',
        myCoins: 'My Coins',
        logout: 'Logout',
        logoutConfirm: {
            title: 'Logging out',
            text: 'All stored data will be removed. Continue?',
            confirmButtonText: 'Logout',
            cancelButtonText: 'Cancel',
        },
    },

    validator: {
        placeholder: 'Choose validator...',
        error: 'Error loading validators',
        menu: {
            copy: 'Copy pubkey',
            delegate: 'Delegate',
            stake: 'My stake',
            openInExplorer: 'View in Explorer',
            change: 'Change validator',
        },
    },

    redelegator: {
        textDelegrating: 'Delegate',
        textFloor: 'but not less than',
        confirm: {
            title: 'Redelegation',
            text: 'You are about to enable autodelegation of your funds. Continue?',
            ok: 'Yes',
            cancel: 'Cancel',
        },
        button: {
            start: 'Start redelegation',
            stop: 'Stop redelegation',
        },
    },

    delegation: {
        title: 'Delegation',
        comissionDisclaimer: 'Comission will be charged in BIPs.',
        sendTx: 'Delegate',
        form: {
            stake: 'Stake:',
            transferAll: 'Delegate everything',
            pubkeyLabel: 'Public key:',
            pubkeyPlaceholder: 'Validator pubkey',
        },
        confirmation: {
            title: 'Confirmation',
            stake: 'You are delegating:',
            pubkey: 'Validator public key:',
        },
    },

    txCommon: {
        messages: {
            success: 'Transaction has been send successfully',
        },
        form: {
            messageLabel: 'Message:',
            messagePlaceholder: 'Transaction message',
            comissionCoinLabel: 'Coin to pay fee:',
            chainIdLabel: 'Chain ID:', 
        },
    },

    common: {
        refresh: 'Refresh',
        close: 'Close',
        nodata: 'No data',
        back: 'Back',
        confirm: 'Confirm',
        fee: 'Fee',
        useMax: 'Use max',
        copyButton: {
            text: 'Copy',
            success: 'Copied!',
        },
        date: {
            today: 'Today',
            yesterday: 'Yesterday',
        },
    },

    txHistory: {
        title: 'Transaction history',
        tabTransactions: 'Transactions',
        tabRewards: 'Rewards',
        filter: {
            all: 'All',
            incoming: 'Incoming',
            outgoing: 'Outgoing',
        },
        types: {
            'reward': 'Reward',
            '-1':  'Sent',
            '1':   'Received',
            '2':   'Sold coin',
            '3':   'Sold all',
            '4':   'Bought coin',
            '5':   'Created coin',
            '6':   'Masternode created',
            '7':   'Delegated',
            '8':   'Unbound',
            '9':   'Redeemed cheque',
            '-9':  'Sent via cheque',
            '10':  'Validator switched on',
            '11':  'Validator switched off',
            '13':  'Received',
            '-13': 'Sent',
            '14':  'Changed settings',
            '15':  'Voted for halt',
            '16':  'Recreated coin',
            '17':  'Edited ticker owner',
            '18':  'Edited multisig',
            '19':  'Voted for price',
            '20':  'Edited candidate public key',
            '21':  'Added liquidity',
            '22':  'Removed liquidity',
            '23':  'Sold via swap pool',
            '24':  'Bought via swap pool',
            '25':  'Sold all via swap pool',
            '26':  'Changed candidate commission',
            '27':  'Moved stake',
            '28':  'Minted token',
            '29':  'Burned token',
            '30':  'Recreated token',
            '31':  'Voted for commission',
            '32':  'Voted for update',
            '33':  'Created swap pool',
        },
        multisend: {
            toAddresses: 'To {0} addresses',
            coinCount: '{0} coins',
        },
        gross: 'Total',
        loadMore: 'Load next',
        info: {
            hash: 'Hash',
            date: 'Timestamp',
            you: 'You',
            amount: 'Amount',
            sender: 'Sender',
            receiver: 'Receiver',
            coinSold: 'Sold',
            coinReceived: 'Received',
            coinName: 'Description',
            coinSymbol: 'Symbol',
            coinInitialAmount: 'Initial amount',
            coinInitialReserve: 'Initial reserve',
            coinCRR: 'CRR',
            payload: 'Comment',
            nopayload: 'Empty',
            moreCoins: 'and {0} more coins',
            addedLiquidity: 'Changed liquidity',
            lpAmount: 'LP tokens received',
        },
        errorMessage: 'Error loading stats',
        notification: {
            title: 'Received money',
        },
    },

    stakeModal: {
        title: 'Delegated coins',
        actionUnbond: 'Unbond',
        unbond: {
            title: 'Unbond coins',
            form: {
                stake: 'Amount:',
                submit: 'Unbond',
            },
            confirmation: {
                title: 'Confirm unbond',
                stake: 'You unbond:',
                pubkey: 'From the masternode:',
                warning: 'Unbonded coins will return to your address in approximately 30 days (518 400 blocks) and will not be generating rewards during this period.',
            },
        },
    },

    coinsModal: {
        title: 'Coins',
        tabs: {
            wallet: 'Wallet',
            buy: 'Purchase',
        },
        buy: {
            title: 'Buy coin {symbol}',
            form: {
                amount: 'Buy amount:',
                coinFrom: 'Coin to spend:',
                approximateTotal: 'You will pay approximately:',
                submit: 'Buy',
            },
            search: {
                placeholder: 'Search coins',
                tip: 'Start typing coin name',
                actionPurchase: 'Purchase',
            },
            confirmation: {
                title: 'Convert coins',
                amountLabel: 'You buy:',
                disclaimer: 'The result amount depends on the exchange rate at the time of buying and may differ from the above.',
            },
        },
        sell: {
            title: 'Selling {symbol}',
            form: {
                amount: 'Sell amount:',
                coinTo: 'Coin to get:',
                approximateTotal: 'You will get approximately:',
                submit: 'Sell',
                sellAll: 'Sell all',
            },
            confirmation: {
                title: 'Convert coins',
                amountLabel: 'You will sell:',
                approximateTotal: 'You will get approximately:',
                disclaimerSellAll: 'All {0} coins will be spent.',
                disclaimer: 'The result amount depends on the exchange rate at the time of the selling and may differ from the above.',
            },
        },
    },

    receiveModal: {
        title: 'Receive funds',
    },

    sendModal: {
        title: 'Send funds',
        form: {
            receiver: 'Receiver:',
            receiverPlaceholder: 'Address or domain',
            amount: 'Amount:',
            submit: 'Send',
        },
        confirmation: {
            title: 'Sending funds',
            amount: 'You are sending:',
            receiver: 'To address:',
        },
        errors: {
            unresolvableDomain: 'Cannot resolve domain',
            invalidAddress: 'Invalid address',
        },
    },

    settings: {
        title: 'Settings',
        altCurrency: 'Alternative currency:',
        language: 'Language:',
        app: 'App:',
        reportErrors: 'Report crashes',
        showNotifications: 'Show desktop notifications',
        appVersion: 'App version',
        installToChrome: 'Install to Chrome',
        currencies: {
            usd: 'US Dollar',
            rub: 'Russian Ruble',
            eur: 'Eurozone Euro',
            cny: 'Chinese Yuan',
            btc: 'Bitcoin',
            bch: 'Bitcoin Cash',
            eth: 'Etherium',
        },
        buttons: {
            telegram: 'Telegram',
            support: 'Get Support',
        },
    },

    electron: {
        update: {
            check: 'Check updates',
            available: 'Update available',
            unavailable: 'No updates',
            downloaded: 'Restart to update',
            checking: 'Requesting update',
            error: 'Error requesting update',
        },
    },

    pwa: {
        text: 'You can install wallet to Chrome and launch it like a native app.',
        install: 'Install',
        dismiss: 'No, thanks',
        help: 'It is still possible to install the app via Settings.',
        okay: 'Fine',
    },

    errors: {
        insufficient_funds: 'Insufficient funds. Reduce amount or change fee coin.',
        insufficient_stake: 'Insufficient stake for sender account.',
        coin_supply_overflow: 'Coin reached max supply: cannot buy more coins.',
    },
};

<template>
    <active-view v-bind:title="title" v-on:back="goBack()">
        <section v-show="!confirmation" class="active-view">
            <el-form label-position="top" class="active-view__body">
                <el-form-item v-bind:label="$t('coinsModal.buy.form.amount')" style="padding-bottom: 18px;">
                    <el-input v-model="txData.valueToBuy">
                        <div slot="append" v-text="coin.symbol"/>
                    </el-input>
                    <span class="d8-action-modal__input-suggestion"
                        v-on:click="setMax()"
                        v-text="`Max ≈ ${maxReadable}`"/>
                </el-form-item>

                <el-row v-bind:gutter="24">
                    <el-col v-bind:span="12">
                        <el-form-item v-bind:label="$t('coinsModal.buy.form.coinFrom')">
                            <ui-coin-selector
                                v-model="txData.coinToSell"
                                v-bind:disabledCoinsIds="disabledBuyCoinsIds"/>
                        </el-form-item>
                    </el-col>

                    <el-col v-bind:span="12">
                        <el-form-item v-bind:label="$t('coinsModal.buy.form.approximateTotal')">
                            <el-input readonly v-bind:value="approximateTotal.toFixed(4)"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div v-show="advancedVisible">
                    <common-tx-params v-bind="tx" v-on:update="updateTxFields"/>
                </div>
            </el-form>

            <footer class="active-view__footer d8-modal-footer">
                <el-button class="button--fat" type="primary"
                    v-bind:disabled="disabled"
                    v-on:click="confirmation = true">
                    {{$t('coinsModal.buy.form.submit')}}
                </el-button>
                <el-button type="text" class="d8-modal-footer__settings"
                    v-on:click="advancedVisible = !advancedVisible">
                    <ui-icon name="settings"/>
                </el-button>
            </footer>
        </section>

        <coin-buy-confirmation class="active-view"
            v-on:confirm="buyCoin()"
            v-on:back="confirmation = false"
            v-if="confirmation"
            v-bind="txData"
            v-bind:error="error"
            v-bind:gasCoin="tx.gasCoin"
            v-bind:isLoading="isLoading"
            v-bind:commission="commission"
            v-bind:approximateTotal="approximateTotal"/>
    </active-view>
</template>

<script>
import { filterNumber, getTranslationKeyByErrorCode } from '~/helpers';
import { txSchema, buyTxDataSchema } from '~/adapters/minter/Schema';
import { estimateCoinSell } from '~/adapters/minter/NodeApi';
import { isCoinObject } from '~/adapters/minter/utils';
import Minter from '~/adapters/minter';
import CoinBuyConfirmation from './CoinBuyConfirmation';
import CommonTxParams from '../ModalPartialTxCommon';
import debounce from 'lodash.debounce';
import Big from 'big.js';

export default {
    props: {
        coin: {
            type: Object,
            required: true,
            validator: isCoinObject,
        },

        coinToPrice: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            isLoading: false,
            advancedVisible: false,
            coinFromPrice: 1,
            error: null,
            commission: 0,
            confirmation: false,
            max: null,
            tx: {},
            txData: {},
        };
    },

    computed: {
        balances() {
            return this.$store.getters.balances.filter(({ ticker }) => ticker != this.coin.symbol);
        },

        title() {
            return this.confirmation
                ? this.$t('coinsModal.buy.confirmation.title')
                : this.$t('coinsModal.buy.title', { symbol: this.coin.symbol });
        },

        approximateTotal() {
            return this.txData.valueToBuy / this.coinFromPrice;
        },

        coinFromBalance() {
            return this.$store.getters.balance(this.txData.coinToSell);
        },

        maxReadable() {
            if (this.max == null) {
                return '...';
            }

            return Math.max(0, parseFloat(this.max)).toFixed(4);
        },

        disabledBuyCoinsIds() {
            return [this.coin.id];
        },

        disabled() {
            const hasEnoughGas = Math.sign(this.$store.getters.balanceBits(this.tx.gasCoin) - this.commission) > 0;
            const amount = parseFloat(this.txData.valueToBuy) || 0;
            const hasCoinsToExchange = this.balances.length > 0;

            return amount == 0
                || amount > parseFloat(this.max)
                || !hasCoinsToExchange
                || !hasEnoughGas;
        },
    },

    created() {
        this.resetTxFields();
    },

    mounted() {
        const suggested = this.balances.find(({ id }) => id != this.coin.id);

        if (suggested) {
            this.txData.coinToSell = suggested;
        }

        this.$watch(vm => [vm.tx.gasCoin.id, vm.tx.payload, vm.txData.coinToSell.id], debounce(function () {
            this.calculateFeeAndMax();
        }, 450), {
            immediate: true,
        });
    },

    watch: {
        'txData.valueToBuy': {
            handler(value) {
                this.txData.valueToBuy = filterNumber(value);
            },
        },

        confirmation() {
            this.error = null;
        },
    },

    methods: {
        goBack() {
            this.confirmation
                ? this.confirmation = false
                : this.$bus.$emit('modalMyCoins:back');
        },

        setMax() {
            this.txData.valueToBuy = Math.max(this.max, 0);
        },

        buyCoin() {
            this.isLoading = true;
            Minter.buy(this.tx, this.txData)
                .then(() => {
                    this.$message.success(this.$t('txCommon.messages.success'));
                    this.confirmation = false;
                    this.goBack();
                })
                .catch(this.handleBuyError)
                .then(() => this.isLoading = false);
        },

        handleBuyError(error) {
            const translationKey = getTranslationKeyByErrorCode(error.code);
            this.error = translationKey
                ? this.$t(translationKey)
                : error.message || error;
        },

        calculateFeeAndMax() {
            Minter.estimateBuyTxCommission(this.tx).then(fee => {
                this.commission = fee;
                this.calculateMax();
            });
        },

        calculateMax() {
            const valueToSell = new Big(this.$store.getters.balanceBits(this.txData.coinToSell.id));

            const params = {
                coinToSell: this.txData.coinToSell,
                coinToBuy: this.txData.coinToBuy,
                valueToSell: this.txData.coinToSell.id === this.tx.gasCoin.id
                    ? valueToSell.sub(this.commission).toString()
                    : valueToSell.toString(),
            };

            estimateCoinSell(params).then(({ will_get }) => {
                const amount = new Big(will_get);
                this.max = amount.div(10 ** 18).toString();
                this.coinFromPrice = amount.div(params.valueToSell).toString();
            });
        },

        updateTxFields(params) {
            Object.assign(this.tx, params);
        },

        resetTxFields() {
            this.tx = Object.assign({}, txSchema);
            this.txData = Object.assign({}, buyTxDataSchema);
            this.txData.coinToBuy = this.coin;
        },
    },

    components: {
        CommonTxParams, CoinBuyConfirmation,
    },
};
</script>

import { TICK_REFRESH_EXCHANGE_RATES_MS } from '~/config';

export default {
    exchangeRatesTimer: null,

    created() {
        this.$store.dispatch('UPDATE_EXCHANGE_RATES');

        this.$options.exchangeRatesTimer = setInterval(() => {
            this.$store.dispatch('UPDATE_EXCHANGE_RATES');
        }, TICK_REFRESH_EXCHANGE_RATES_MS);
    },

    beforeDestroy() {
        clearInterval(this.$options.exchangeRatesTimer);
        this.$options.exchangeRatesTimer = null;
    },
};

import { Minter, TX_TYPE, prepareSignedTx } from 'minter-js-sdk/dist/cjs';
import { flattenCoinObjects, dereference } from '~/adapters/minter/utils';
import { vault } from '~/vault';
import { NODE_API_URL } from '~/config';
import Vue from 'vue';
import {
    txSchema,
    sendTxDataSchema,
    delegateTxDataSchema,
    unbondTxDataSchema,
    sellTxDataSchema,
    buyTxDataSchema,
} from './Schema';

const bogusAddress = 'Mx' + '0'.repeat(40);
const bogusPrivateKey = '0x' + 'e'.repeat(64);
const bogusValidatorPubkey = 'Mp' + '0'.repeat(64);

export const MinterSDK = new Minter({
    apiType: 'node',
    baseURL: NODE_API_URL,
});

export const MinterAPI = MinterSDK.apiInstance;

const broadcastTransactionEvent = function(txClassName, txParams, txHash) {
    const eventName = txClassName.replace('TxParams', 'Transaction');
    Vue.prototype.$bus.$emit(eventName, {
        txParams, txHash,
    });
};

const signAndPostTx = function signTxWithCurrentWalletPrivkeyAndSend(params) {
    const txParams = dereference(params);
    flattenCoinObjects(txParams);

    let { privateKey } = vault.get('wallet');
    privateKey = '0x' + privateKey.replace('0x', '');

    return MinterSDK.postTx(txParams, { privateKey })
        .then((txHash) => {
            broadcastTransactionEvent(txParams.type, txParams, txHash);
            return Promise.resolve(txHash);
        })
        .catch((error) => {
            const apiError = error.response?.data?.error;

            if (apiError) {
                apiError.code = parseInt(apiError.code);
                Object.freeze(apiError);
                Object.freeze(apiError.data);
            }

            return Promise.reject(apiError || error);
        });
};

const estimateBogusTxCommission = function (params) {
    const txParams = dereference({ ...params, nonce: 1 });

    flattenCoinObjects(txParams);

    const tx = prepareSignedTx(txParams, {
        privateKey: bogusPrivateKey,
    });

    return MinterSDK.estimateTxCommission(tx.serializeToString()).then(({ commission }) => {
        return commission * (10 ** 18);
    });
};

export default {
    send(txParams, data) {
        return signAndPostTx({ ...txParams, data,
            type: TX_TYPE.SEND,
        });
    },

    estimateSendTxCommission(txParams) {
        return estimateBogusTxCommission({ ...txParams,
            type: TX_TYPE.SEND,
            data: { ...sendTxDataSchema,
                to: bogusAddress,
            },
        });
    },

    delegate(txParams, data) {
        return signAndPostTx({ ...txParams, data,
            type: TX_TYPE.DELEGATE,
        });
    },

    estimateDelegateTxCommission(txParams) {
        return estimateBogusTxCommission({ ...txParams,
            type: TX_TYPE.DELEGATE,
            data: { ...delegateTxDataSchema,
                publicKey: bogusValidatorPubkey,
            },
        });
    },

    unbond(txParams, data) {
        return signAndPostTx({ ...txParams, data,
            type: TX_TYPE.UNBOND,
        });
    },

    estimateUnbondTxCommission(txParams) {
        return estimateBogusTxCommission({ ...txParams,
            type: TX_TYPE.UNBOND,
            data: { ...unbondTxDataSchema,
                publicKey: bogusValidatorPubkey,
            },
        });
    },

    sell(txParams, data) {
        return signAndPostTx({ ...txParams, data,
            type: TX_TYPE.SELL,
        });
    },

    estimateSellTxCommission(txParams) {
        return estimateBogusTxCommission({ ...txParams,
            type: TX_TYPE.SELL,
            data: { ...sellTxDataSchema },
        });
    },

    sellAll(txParams, data) {
        return signAndPostTx({ ...txParams, data,
            type: TX_TYPE.SELL_ALL,
        });
    },

    // TODO
    estimateSellAllTxCommission(txParams) {
        return this.estimateSellTxCommission(txParams);
    },

    buy(txParams, data) {
        return signAndPostTx({ ...txParams, data,
            type: TX_TYPE.BUY,
        });
    },

    estimateBuyTxCommission(txParams) {
        return estimateBogusTxCommission({ ...txParams,
            type: TX_TYPE.BUY,
            data: { ...buyTxDataSchema },
        });
    },
};

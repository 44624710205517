<template>
    <div class="tx-history-item" style="border-bottom: 1px solid #EBEEF5;">
        <span class="tx-history-item__image">
            <img v-if="avatar" class="tx-history-item__avatar" v-bind:src="avatar">
            <span v-else class="tx-history-item__icon">
                <ui-icon name="mui_validator"/>
            </span>
        </span>

        <div class="tx-history-item__info">
            <span class="tx-history-item__type" v-text="$t('txHistory.types.reward')"/>
            <span class="tx-history-item__reward">
                <div class="tx-history-item-address">
                    <span v-once
                        v-if="txContactName"
                        class="tx-history-item-address__readable"
                        v-text="txContactName"/>
                    <span v-once v-else
                        class="ui-looong tx-history-item-address__collapsed"
                        v-bind:data-loopa="pubkey.substr(0, pubkey.length - 6)"
                        v-bind:data-poopa="pubkey.substr(pubkey.length - 6)">
                    </span>
                </div>
            </span>
        </div>

        <div v-once class="tx-history-item-amount">
            <span class="tx-history-item-amount__value" v-text="readableAmount"/>
            <div class="tx-history-item-amount__coin" v-text="coinName"/>
        </div>
    </div>
</template>

<script>
import { defaultCoin } from '~/adapters/minter/Schema';

export default {
    props: {
        pubkey: {
            type: String,
            required: true,
        },
        amount: {
            type: [Number, String],
            required: true,
        },
    },

    computed: {
        coinName() {
            return defaultCoin.symbol;
        },

        validator() {
            return this.$store.getters.findValidator(this.pubkey);
        },

        avatar() {
            return this.validator.icon_url;
        },

        txContactName() {
            return this.validator.name;
        },

        readableAmount() {
            return parseFloat(this.amount).toFixed(4);
        },
    },
};
</script>

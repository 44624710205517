<template>
    <section>
        <nav class="tx-history-filter">
            <el-radio-group class="tx-history-filter__group" v-model="filter">
                <el-radio-button label="all">
                    {{$t('txHistory.filter.all')}}
                </el-radio-button>
                <el-radio-button label="inc">
                    {{$t('txHistory.filter.incoming')}}
                </el-radio-button>
                <el-radio-button label="out">
                    {{$t('txHistory.filter.outgoing')}}
                </el-radio-button>
            </el-radio-group>
        </nav>

        <el-collapse accordion class="d8-stats-list__collapse">
            <div class="d8-stats-list__empty-block" v-if="transactions.length == 0">
                <span v-text="$t('common.nodata')"/>
            </div>
            <section v-for="(group, date) in groupedHistory"
                v-bind:hidden="group.hidden">
                <transaction-separator v-bind:date="date"/>
                <el-collapse-item class="d8-stats-list__collapse-item el-collapse-item--noarrow"
                    v-for="tx in group.list"
                    v-bind:key="tx.txn"
                    v-bind:hidden="tx.hidden">
                    <ui-transaction slot="title" v-bind="tx"/>
                    <ui-transaction-details v-bind="tx" v-bind:data="tx.data"/>
                </el-collapse-item>
            </section>
        </el-collapse>

        <footer class="tx-history-loadmore" v-show="hasMoreHistory && transactions.length > 0">
            <el-button type="primary"
                class="tx-history-loadmore__button"
                v-if="hasMoreHistory"
                v-bind:loading="isLoading"
                v-bind:disabled="isLoading"
                v-on:click="$emit('loadMore')">
                {{$t('txHistory.loadMore')}}
            </el-button>
        </footer>
    </section>
</template>

<script>
import UiTransaction from './Transaction';
import UiTransactionDetails from './TransactionDetails';
import TransactionSeparator from './TransactionSeparator';
import { mapState } from 'vuex';

export default {
    props: {
        transactions: {
            required: true,
            type: Array,
        },
        hasMoreHistory: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedFilter: 'all',
            filter: 'all',
        };
    },

    computed: {
        ...mapState({
            address: state => state.wallet.address,
        }),

        filtered() {
            if (this.selectedFilter == 'all') {
                return this.transactions;

            } else if (this.selectedFilter == 'inc') {
                return this.transactions.map(({ from, ...details }) => {
                    const visible = from != this.address;
                    return { ...details, from,
                        hidden: !visible,
                    };
                });

            } else if (this.selectedFilter == 'out') {
                return this.transactions.map(({ from, type, ...details }) => {
                    const visible = from == this.address;
                    return { ...details, from, type,
                        hidden: !visible,
                    };
                });
            }
        },

        groupedHistory() {
            const grouped = this.filtered.reduce((accumulator, tx) => {
                const date = new Date(tx.timestamp);
                date.setHours(0, 0, 0, 0);
                const groupKey = date.toISOString();
                if (!accumulator[groupKey]) {
                    accumulator[groupKey] = { list: [], hidden: false };
                }
                accumulator[groupKey].list.push(tx);
                return accumulator;
            }, {});

            Object.values(grouped).forEach((group) => {
                group.hidden = group.list.every(tx => tx.hidden);
            });

            return grouped;
        },
    },

    watch: {
        // Сначала переключаем кнопку фильтра, затем запускаем ре-рендер списка:
        filter(value) {
            setTimeout(() => this.selectedFilter = value, 50);
        },
    },

    components: {
        UiTransaction, TransactionSeparator, UiTransactionDetails,
    },
};
</script>


<style lang="scss">
.tx-history-filter {
    padding: 16px 10px 0;
    &__group {
        display: flex;
        background: #f0f1f4;
        padding: 4px;
        border-radius: 24px;
    }
    .el-radio-button {
        flex: 1;
        margin: 0 2px;
        &__inner {
            border-radius: 24px !important;
            color: #888;
            width: 100%;
            padding: 9px 15px;
            font-size: 12px;
            background-color: transparent;
            border: none !important;
            line-height: 13px;
            font-weight: normal;
            transition: none;
            &:hover {
                color: #333;
                background-color: rgba(0, 0, 0, 0.07);
            }
        }
        &:focus, &:active {
            box-shadow: none !important;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .el-radio-button:first-child .el-radio-button__inner {
        box-shadow: unset !important;
    }
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #FFF;
        color: #444;
        border: none;
        font-weight: 500;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 3px 0px !important;
    }
    .tx-history-item__dummy {
        height: 39px;
        border-radius: 24px;
    }
}
.tx-history-loadmore {
    padding: 16px 16px 6px;
    margin: auto;
    text-align: center;
}

@media all and (max-width: 480px) {
    .tx-history-filter {
        .el-radio-button {
            &__inner {
                padding: 12px 20px;
                font-size: 14px;
                line-height: 14px;
            }
        }
        .tx-history-item__dummy {
            height: 46px;
        }
    }
    .tx-history-loadmore {
        padding: 22px;
    }
}
</style>

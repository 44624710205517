import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { APP_VERSION, APP_ENV, APP_IS_ELECTRON, SENTRY_DSN, SENTRY_PROJECT } from '~/config';
import { debugWrite } from '~/logging';
import store from '~/store';

const debugLog = (...args) => debugWrite('Sentry', ...args);

const vueIntegration = new Integrations.Vue({
    Vue, attachProps: true,
});

export function registerSentry() {
    const release = `${SENTRY_PROJECT}@${APP_VERSION}` + (APP_IS_ELECTRON ? '-electron' : '');
    const debug = APP_ENV == 'development';
    const enableReports = store.state.settings.reportErrors;

    if (debug && !enableReports) {
        return debugLog('Sentry is disabled while in debug mode');
    }

    Sentry.init({
        release, debug,
        environment: APP_ENV,
        dsn: SENTRY_DSN,
        maxBreadcrumbs: 24,
        integrations: [ vueIntegration ],
        beforeBreadcrumb(breadcrumb, hint) {
            // Убираем выводы в консоль из logging.js:
            if (breadcrumb.category == 'console' && breadcrumb.data.extra.arguments.length >= 2) {
                return null;
            }

            return breadcrumb;
        },
    });

    updateSentryReportingSettings(enableReports);
};

export function updateSentryReportingSettings(enableReports) {
    const SentryClient = Sentry.getCurrentHub().getClient();

    if (! SentryClient) {
        return debugLog('Sentry is not inited. Reload page for changes to make effect.');
    }

    SentryClient.getOptions().enabled = enableReports;

    return debugLog('Sentry error reporting is now', enableReports ? 'enabled' : 'disabled');
};

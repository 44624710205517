<template>
    <el-dialog width="300px" class="el-dialog--no-pad el-dialog--pwa"
        v-bind:visible.sync="isVisible"
        v-bind:show-close="false">
        <section v-show="dismissed">
            <div class="d8-pwa-text" v-text="$t('pwa.help')"/>
        </section>
        <section v-show="!dismissed">
            <header v-once class="d8-pwa-showcase">
                <span class="d8-pwa-showcase__bogus"/>
                <img class="d8-pwa-showcase__logo" src="/img/touch/chrome-touch-icon-192x192.png" width="96" height="96">
                <span class="d8-pwa-showcase__bogus"/>
            </header>
            <div class="d8-pwa-text" v-text="$t('pwa.text')"/>
        </section>
        <footer slot="footer" class="dialog-footer">
            <div v-if="!dismissed">
                <el-button v-if="isVisible" id="install_pwa" type="primary" class="button--fat" v-text="$t('pwa.install')"/>
                <div class="d8-pwa-dismiss" v-on:click="dismiss()" v-text="$t('pwa.dismiss')"/>
            </div>
            <div v-else>
                <el-button type="primary" class="button--fat" v-text="$t('pwa.okay')" v-on:click="isVisible = false"/>
            </div>
        </footer>        
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            dismissed: false,
            isVisible: false,
        };
    },

    created() {
        this.$bus.$on('PWAInstallReady', () => {
            if (! this.$store.state.settings.pwaDismissed) {
                this.isVisible = true;
            }
        });

        this.$bus.$on('PWAInstallFinished', () => {
            this.isVisible = false;
            this.$store.commit('updateSettings', { pwaDismissed: false });
        });
    },

    beforeDestroy() {
        this.$bus.$off('PWAInstallReady');
        this.$bus.$off('PWAInstallFinished');
    },

    watch: {
        isVisible(visible) {
            if (visible) {
                this.dismissed = false;
            }
        },
    },

    methods: {
        dismiss() {
            this.dismissed = true;
            this.$store.commit('updateSettings', { pwaDismissed: true });
        },
    },
};
</script>

<style lang="scss">
.el-dialog--pwa {
    .el-dialog {
        margin: auto;
    }
}
.d8-pwa-showcase {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
    padding-top: 20px;
    &__logo {
        display: block;
        flex-shrink: 0;
        margin: 0 20px;
        background-color: #1989fa;
        border-radius: 14px;
    }
    &__bogus {
        width: 96px;
        height: 96px;
        flex-shrink: 0;
        background-color: #EFEFEF;
        background-size: 48px;
        background-repeat: no-repeat;
        background-position: center;
        &:first-child {
            border-radius: 0 14px 14px 0;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4h-4z" fill="%23E4E4E4"/></svg>');
        }
        &:last-child {
           border-radius: 14px 0 0 14px;
           background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" fill="%23E4E4E4"/></svg>');
           
        }
    }
}
.d8-pwa-text {
    padding: 20px 20px 10px 20px;
    font-size: 16px;
    line-height: 1.5;
    color: #222;
    hyphens: auto;
    text-align: center;
    word-break: break-word;
}
.d8-pwa-dismiss {
    font-size: 12px;
    color: #a4a4a4;
    margin: 0 0 -10px 0;
    padding: 6px 0;
    font-weight: 500;
    cursor: pointer;
}
</style>

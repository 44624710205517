<template>
    <el-select v-model="selectedCoin" popper-class="d8-dd-balances" value-key="id">
        <el-option class="d8-dd-balances__item"
            v-for="coin in balances"
            v-bind:label="coin.symbol"
            v-bind:key="coin.id"
            v-bind:value="coin"
            v-bind:disabled="disabledCoinsIds.indexOf(coin.id) > -1">
            <span class="d8-dd-balances__ticker" v-text="coin.symbol"/>
            <span class="d8-dd-balances__balance" v-text="coin.balance.toFixed(4)"/>
        </el-option>
    </el-select>
</template>

<script>
import { isCoinObject } from '~/adapters/minter/utils';
import { defaultCoin } from '~/adapters/minter/Schema';

export default {
    props: {
        value: {
            type: Object,
            required: true,
            validator: isCoinObject,
        },

        disabledCoinsIds: {
            type: Array,
            required: false,
            default: () => ([]),
        },
    },

    computed: {
        balances() {
            return this.$store.getters.balances;
        },

        selectedCoin: {
            get() {
                return this.value;
            },

            set({ id, symbol }) {
                if (id !== undefined && symbol !== undefined) {
                    this.$emit('input', { id, symbol });
                }
            },
        },
    },
};
</script>

import { vault } from '../vault';
import { updateSentryReportingSettings } from './sentry';

export default {
    beforeCreate() {
        const { privateKey, address } = vault.get('wallet');
        privateKey && address
            ? this.$store.commit('updateWallet', { address, privateKey })
            : vault.removeAll();

        this.$store.commit('updateValidator', vault.get('validator'));
        this.$store.commit('updateSettings', vault.get('settings'));
    },

    created() {
        this.$store.subscribe((mutation, state) => {
            switch (mutation.type) {
                case 'updateWallet': {
                    const { privateKey, address } = mutation.payload;
                    return privateKey && address
                        ? vault.set('wallet', { privateKey, address })
                        : vault.remove('wallet');
                }

                case 'updateValidator': {
                    const pubkey = mutation.payload.pubkey;
                    return pubkey
                        ? vault.set('validator', { pubkey })
                        : vault.remove('validator');
                }

                case 'updateSettings': {
                    if ('language' in mutation.payload) {
                        this.$i18n.locale = mutation.payload.language;
                    }

                    if ('reportErrors' in mutation.payload) {
                        updateSentryReportingSettings(mutation.payload.reportErrors);
                    }

                    return state.settings
                        ? vault.set('settings', state.settings)
                        : vault.remove('settings');
                }

                case 'resetWallet':
                    return vault.removeAll();
                    break;
            }
        });
    },
};

<template>
    <section>
        <section v-show="!confirmation">
            <el-page-header class="active-view__header"
                v-bind:title="$t('common.back')"
                v-on:back="$emit('back')"
                v-bind:content="$t('stakeModal.unbond.title')"/>

            <el-form label-position="top" class="active-view__body">
                <el-form-item v-bind:label="$t('stakeModal.unbond.form.stake')">
                    <el-input class="active-view-input" placeholder="0" v-model="txData.stake">
                        <div slot="append">
                            <span class="active-view-input__max"
                                v-on:click="txData.stake = maxStake"
                                v-text="$t('common.useMax')"/>
                            <div v-text="txData.coin.symbol"/>
                        </div>
                    </el-input>
                    <div v-if="error" v-text="error" class="el-form-item__error"/>
                </el-form-item>

                <div class="d8-action-modal__extras" v-show="advancedVisible">
                    <common-tx-params v-bind="tx" v-on:update="updateTxFields"/>
                </div>
            </el-form>

            <footer class="active-view__footer d8-modal-footer">
                <el-button class="button--fat" type="primary"
                    v-bind:disabled="isDisabled"
                    v-on:click="confirmation = true">
                    {{$t('stakeModal.unbond.form.submit')}}
                </el-button>
                <el-button type="text" class="d8-modal-footer__settings"
                    v-on:click="advancedVisible = !advancedVisible">
                    <ui-icon name="settings"/>
                </el-button>
            </footer>
        </section>

        <unbond-confirmation v-if="confirmation"
            v-on:back="confirmation = false"
            v-on:submit="unbond()"
            v-bind:isLoading="isLoading"
            v-bind:gasCoin="tx.gasCoin"
            v-bind:commission="commission"
            v-bind:error="error"
            v-bind="txData"/>
    </section>
</template>

<script>
import { filterNumber, getTranslationKeyByErrorCode } from '~/helpers';
import { txSchema, unbondTxDataSchema } from '~/adapters/minter/Schema';
import { isCoinObject } from '~/adapters/minter/utils';
import UnbondConfirmation from './ModalStakeUnbondConfirmation';
import CommonTxParams from './ModalPartialTxCommon';
import debounce from 'lodash.debounce';
import Minter from '~/adapters/minter';

export default {
    props: {
        coin: {
            required: true,
            validator: isCoinObject,
        },
    },

    data() {
        return {
            commission: null,
            isLoading: false,
            confirmation: false,
            advancedVisible: false,
            error: null,
            tx: {},
            txData: {},
        };
    },

    computed: {
        maxStake() {
            const stakes = this.$store.getters.stakes;
            const selectedStake = stakes.find(({ coin }) => coin.id == this.coin.id);

            return selectedStake.value_exact;
        },

        feeCoinBalance() {
            return this.$store.getters.balanceBits(this.tx.gasCoin);
        },

        isDisabled() {
            return this.txData.stake <= 0
                || this.txData.stake > parseFloat(this.maxStake)
                || this.commission > this.feeCoinBalance
                || !isFinite(this.txData.stake);
        },
    },

    watch: {
        'txData.stake': {
            handler(value) {
                this.error = '';
                this.txData.stake = filterNumber(value);
            },
        },
    },

    created() {
        this.resetTxFields();
    },

    mounted() {
        this.$watch(vm => [vm.tx.gasCoin.id, vm.tx.payload], debounce(function () {
            this.updateEstimatedCommission();
        }, 450), {
            immediate: true,
        });
    },

    methods: {
        unbond() {
            this.isLoading = true;
            Minter.unbond(this.tx, this.txData)
                .then(() => {
                    this.$message.success(this.$t('txCommon.messages.success'));
                    this.$emit('back');
                })
                .catch(this.handleUnbondError)
                .then(() => this.isLoading = false);
        },

        handleUnbondError(error) {
            const translationKey = getTranslationKeyByErrorCode(error.code);
            this.error = translationKey
                ? this.$t(translationKey)
                : error.message || error;
        },

        updateEstimatedCommission() {
            Minter.estimateUnbondTxCommission(this.tx).then(fee => this.commission = fee);
        },

        resetTxFields() {
            this.tx = Object.assign({}, txSchema);
            this.txData = Object.assign({}, unbondTxDataSchema);

            this.txData.coin = this.coin;
            this.txData.publicKey = this.$store.state.validator.pubkey;
        },

        updateTxFields(params) {
            Object.assign(this.tx, params);
        },
    },

    components: {
        CommonTxParams,
        UnbondConfirmation,
    },
};
</script>

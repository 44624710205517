<template>
    <section>
        <div class="d8-stats-list__empty-block" v-if="rewards.length == 0">
            <span v-text="$t('common.nodata')"/>
        </div>

        <section v-for="(group, date) in groupedHistory">
            <transaction-separator v-bind:date="date" v-bind:total="group.total"/>
            <ui-reward v-for="(reward, key) in group.rewards"
                v-bind:key="key"
                v-bind:pubkey="reward.validator.public_key"
                v-bind:amount="reward.amount"/>
            <div v-if="group.rewards.length > 1" class="tx-history-reward-gross">
                <span class="tx-history-reward-gross__title" v-text="$t('txHistory.gross')"/>
                <span class="tx-history-reward-gross__sum">
                    <span class="tx-history-reward-gross__amount">
                        {{group.total.toFixed(4)}} BIP
                    </span> 
                </span>
            </div>
        </section>
    </section>
</template>

<script>
import UiReward from './Reward';
import TransactionSeparator from './TransactionSeparator';
import { getAggregatedRewards } from '~/adapters/minter/ExplorerApi';

export default {
    data() {
        return {
            isLoading: false,
            rewards: [],
        };
    },

    computed: {
        address() {
            return this.$store.state.wallet.address;
        },

        groupedHistory() {
            return this.rewards.reduce((accumulator, tx) => {
                const groupKey = tx.time_id;
                if (!accumulator[groupKey]) {
                    accumulator[groupKey] = { rewards: [], total: 0 };
                }
                accumulator[groupKey].rewards.push(tx);
                accumulator[groupKey].total += parseFloat(tx.amount);
                return accumulator;
            }, {});
        },
    },

    created() {
        this.updateData();
        this.$bus.$on('modalStats:update', this.updateData);
    },

    beforeDestroy() {
        this.$bus.$off('modalStats:update', this.updateData);
    },

    methods: {
        getAvatar(pubkey) {
            return this.$store.getters.findValidator(pubkey).meta.icon_url;
        },

        updateData() {
            this.isLoading = true;
            getAggregatedRewards(this.address)
                .then((response) => this.rewards = response.data)
                .catch(() => this.$message.error(this.$t('txHistory.errorMessage')))
                .then(() => this.isLoading = false);
        },
    },

    components: {
        UiReward, TransactionSeparator,
    },
};
</script>

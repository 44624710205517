import { APP_ENV } from '~/config';
import * as Sentry from '@sentry/browser';

const debug = APP_ENV == 'development';
let previous;

const remember = (key) => {
    const wasSame = previous == key;
    previous = key;
    return wasSame;
};

export function debugWrite(name, ...args) {
    if (remember(name + args[0])) return;

    Sentry.addBreadcrumb({
        category: name,
        message: args[0],
        level: Sentry.Severity.Debug,
        data: args,
    });

    if (debug) {
        console.debug(`%c[${name}]%c ${args[0]}`, 'font-weight: bolder;', ...args);

        const mobileConsole = document.getElementById('console');

        if (mobileConsole) {
            mobileConsole.insertAdjacentText('beforeend', `[${name}] ${args[0]}\n`);
            mobileConsole.scrollTo(0, mobileConsole.scrollHeight);
        }
    }
};

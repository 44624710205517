<template>
    <el-dialog class="wallet-dialog el-dialog--no-pad wallet-dialog--m-fullscreen"
        v-bind:visible.sync="isVisible"
        v-bind="dialogAttributes">

        <active-view hide-desktop-title
            v-show="view == 'main'"
            v-on:back="isVisible = false"
            v-bind:title="$t('coinsModal.title')">
            <template v-slot:header>
                <el-tabs v-model="activeTab">
                    <el-tab-pane v-bind:label="$t('coinsModal.tabs.wallet')" name="sell">&nbsp;</el-tab-pane>
                    <el-tab-pane v-bind:label="$t('coinsModal.tabs.buy')" name="buy">&nbsp;</el-tab-pane>
                </el-tabs>
            </template>

            <tab-coin-sell v-show="activeTab === 'sell'"/>
            <tab-coin-buy v-show="activeTab === 'buy'"/>

            <footer class="el-dialog__footer"/>
        </active-view>

        <component v-bind:is="displayedComponent" v-bind="viewData"/>
    </el-dialog>
</template>

<script>
import TabCoinBuy from './TabCoinBuy';
import TabCoinSell from './TabCoinSell';
import CoinBuyForm from './CoinBuyForm';
import CoinSellForm from './CoinSellForm';
import mobileDialogMixin from '~/mixins/mobileDialogs';

export default {
    data() {
        return {
            isVisible: false,
            activeTab: 'sell',
            view: 'main',
            viewData: {},
        };
    },

    computed: {
        displayedComponent() {
            switch (this.view) {
                case 'buyCoin':  return 'coin-buy-form';
                case 'sellCoin': return 'coin-sell-form';
                default: return null;
            }
        },
    },

    created() {
        this.$bus.$on('modalMyCoins:show', () => this.isVisible = true);
        this.$bus.$on('modalMyCoins:back', () => this.view = 'main');

        this.$bus.$on('modalMyCoins:buyCoin', (coin) => {
            this.view = 'buyCoin';
            this.viewData = {
                coin: Object.freeze(coin),
            };
        });

        this.$bus.$on('modalMyCoins:sellCoin', (coin) => {
            this.view = 'sellCoin';
            this.viewData = {
                coin: Object.freeze(coin),
            };
        });
    },

    beforeDestroy() {
        this.$bus.$off('modalMyCoins:show');
        this.$bus.$off('modalMyCoins:back');
        this.$bus.$off('modalMyCoins:buyCoin');
        this.$bus.$off('modalMyCoins:sellCoin');
    },

    watch: {
        isVisible(visible) {
            if (! visible) this.view = 'main';
        }
    },

    mixins: [
        mobileDialogMixin,
    ],

    components: {
        TabCoinBuy, CoinBuyForm,
        TabCoinSell, CoinSellForm,
    },
};
</script>

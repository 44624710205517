import { debugWrite } from '~/logging';

const debugLog = (...args) => debugWrite('Electron renderer', ...args);
const bips = (coin) => coin.ticker == 'BIP';

export default {
    $unwatch: undefined,

    init({ $bus, $store }) {
        if (!('electronBridge' in window) || this.$unwatch) {
            return; 
        }

        window.electronBridge.registerBus($bus);

        this.$unwatch = $store.watch((state) => state.wallet.balances, (now, was) => {
            const nowBalance = now.find(bips) || {};
            const wasBalance = was.find(bips) || {};

            if (nowBalance.bits != wasBalance.bits) {
                return $bus.toElectron('balanceUpdated', nowBalance.balance);
            }
        });

        return debugLog('Registered bus bridge');
    },

    destroy() {
        if (this.$unwatch) this.$unwatch();
    },
};

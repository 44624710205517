<template>
    <el-dialog class="wallet-dialog el-dialog--no-pad" top="20px"
        v-bind:visible.sync="isVisible">
        <delegated-coins
            v-show="!selectedCoin"
            v-on:back="isVisible = false"
            v-on:selectCoin="(coin) => selectedCoin = coin"/>
        <unbond-form
            v-if="selectedCoin"
            v-on:back="selectedCoin = null"
            v-bind:coin="selectedCoin"/>
        <footer v-show="!selectedCoin" class="el-dialog__footer"/>
    </el-dialog>
</template>

<script>
import UnbondForm from './ModalStakeUnbondForm';
import DelegatedCoins from './ModalStakeCoinList';

export default {
    data() {
        return {
            isVisible: false,
            selectedCoin: null,
        };
    },

    created() {
        this.$bus.$on('modalStake:show', () => this.isVisible = true);
    },

    beforeDestroy() {
        this.$bus.$off('modalStake:show');
    },

    watch: {
        isVisible(visible) {
            if (! visible) {
                this.selectedCoin = null;
            }
        },
    },

    components: {
        UnbondForm,
        DelegatedCoins,
    },
};
</script>

import { NODE_API_URL, EXPLORER_API_URL } from '~/config';
import axiosRetry from 'axios-retry';
import axios from 'axios';

axiosRetry(axios, {
    retries: 1,
    retryDelay: (retryCount) => retryCount * 1500,
});

axios.interceptors.request.use((request) => {
    let url = request.url;

    if (url.startsWith('api://')) {
        url = NODE_API_URL + url.replace('api://', '/');

    } else if (url.startsWith('explorer://')) {
        url = EXPLORER_API_URL + url.replace('explorer://', '/');
    }

    return Object.assign(request, { url });
});

export default axios;

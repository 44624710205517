<template>
    <section>
        <div class="active-view__body">
            <el-form label-position="top" class="confirmation" v-loading="isLoading">
                <el-form-item v-bind:label="$t('coinsModal.sell.confirmation.amountLabel')">
                    <el-input readonly class="confirmation__input" v-bind:value="readableToSell"/>
                </el-form-item>

                <el-form-item v-bind:label="$t('coinsModal.sell.confirmation.approximateTotal')">
                    <el-input readonly class="confirmation__input" v-bind:value="readableToGet"/>
                </el-form-item>

                <el-form-item v-bind:label="$t('common.fee') + ':'">
                    <el-input readonly class="confirmation__input" v-bind:value="readableCommission"/>
                </el-form-item>

                <div class="confirmation__disclaimer" v-if="sellAll"
                    v-text="$t('coinsModal.sell.confirmation.disclaimerSellAll', [ coinToSell.symbol ])"/>

                <div class="confirmation__disclaimer"
                    v-text="$t('coinsModal.sell.confirmation.disclaimer')"/>

                <div class="confirmation__disclaimer confirmation__disclaimer--error" v-if="error" v-text="error"/>
            </el-form>
        </div>

        <footer class="active-view__footer d8-modal-footer">
            <el-button v-if="!error" class="button--fat" type="primary"
                key="confirm_button"
                v-on:click="$emit('confirm')">
                {{$t('common.confirm')}}
            </el-button>
            <el-button v-else class="button--fat" v-on:click="$emit('back')">
                {{$t('common.back')}}
            </el-button>
        </footer>
    </section>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: () => false,
        },
        valueToSell: {
            type: [Number, String],
            required: true,
        },
        coinToSell: {
            type: Object,
            required: true,
        },
        coinToBuy: {
            type: Object,
            required: true,
        },
        gasCoin: {
            type: Object,
            required: true,
        },
        commission: {
            type: String,
            required: true,
        },
        approximateTotal: {
            type: [Number, String],
            required: true,
        },
        sellAll: {
            type: Boolean,
            required: true,
        },
        error: {
            required: false,
        },
    },

    computed: {
        readableToSell() {
            return `${this.valueToSell} ${this.coinToSell.symbol}`;
        },

        readableToGet() {
            return `${this.approximateTotal} ${this.coinToBuy.symbol}`;
        },

        readableCommission() {
            return (this.commission / 10 ** 18) + ' ' + this.gasCoin.symbol;
        },
    },
};
</script>

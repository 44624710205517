<template>
    <active-view v-bind:title="title" v-on:back="goBack()">
        <section v-show="!confirmation" class="active-view">
            <el-form label-position="top" class="active-view__body">
                <el-form-item v-bind:label="$t('coinsModal.sell.form.amount')">
                    <el-input v-model="txData.valueToSell" v-bind:disabled="sellAll">
                        <div slot="append" v-text="coin.symbol"/>
                    </el-input>
                    <span class="d8-action-modal__input-suggestion"/>
                </el-form-item>

                <el-form-item style="margin-top: -26px; margin-bottom: 10px">
                    <el-checkbox v-model="sellAll" style="color: #a8afbc">
                        <span style="font-size: 12px; font-weight: 500; margin-left: -4px" v-text="$t('coinsModal.sell.form.sellAll')"/>
                    </el-checkbox>
                </el-form-item>

                <el-row v-bind:gutter="24">
                    <el-col v-bind:span="12">
                        <el-form-item v-bind:label="$t('coinsModal.sell.form.coinTo')">
                            <el-select remote filterable popper-class="d8-dd-balances" value-key="id"
                                v-model="txData.coinToBuy"
                                v-bind:remote-method="searchCoins">
                                <el-option class="d8-dd-balances__item"
                                    v-for="coin in availableCoins"
                                    v-bind:value="coin"
                                    v-bind:key="coin.id"
                                    v-bind:label="coin.symbol"/>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col v-bind:span="12">
                        <el-form-item v-bind:label="$t('coinsModal.sell.form.approximateTotal')">
                            <el-input readonly v-bind:value="approximateTotal.toFixed(4)"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div v-show="advancedVisible">
                    <common-tx-params v-bind="tx" v-on:update="updateTxFields"/>
                </div>
            </el-form>

            <footer class="active-view__footer d8-modal-footer">
                <el-button class="button--fat" type="primary"
                    v-bind:disabled="disabled"
                    v-on:click="confirmation = true">
                    {{$t('coinsModal.sell.form.submit')}}
                </el-button>
                <el-button type="text" class="d8-modal-footer__settings"
                    v-on:click="advancedVisible = !advancedVisible">
                    <ui-icon name="settings"/>
                </el-button>
            </footer>
        </section>

        <coin-sell-confirmation class="active-view"
            v-if="confirmation"
            v-bind="txData"
            v-bind:gasCoin="tx.gasCoin"
            v-bind:sellAll="sellAll"
            v-bind:isLoading="isLoading"
            v-bind:commission="commission"
            v-bind:approximateTotal="approximateTotal"
            v-bind:error="error"
            v-on:confirm="sellCoin()"
            v-on:back="confirmation = false"/>
    </active-view>
</template>

<script>
import { filterNumber, getTranslationKeyByErrorCode } from '~/helpers';
import { defaultCoin, txSchema, sellTxDataSchema } from '~/adapters/minter/Schema';
import { isCoinObject } from '~/adapters/minter/utils';
import { searchCoins } from '~/adapters/minter/ExplorerApi';
import { estimateCoinSell } from '~/adapters/minter/NodeApi';
import Minter from '~/adapters/minter';
import CoinSellConfirmation from './CoinSellConfirmation';
import CommonTxParams from '../ModalPartialTxCommon';
import debounce from 'lodash.debounce';
import Big from 'big.js';

export default {
    props: {
        coin: {
            type: Object,
            required: true,
            validator: isCoinObject,
        },
    },

    data() {
        return {
            confirmation: false,
            advancedVisible: false,
            availableCoins: [],
            exchangeRate: 0,
            sellAll: false,
            isLoading: false,
            commission: 0,
            tx: {},
            txData: {},
        };
    },

    computed: {
        disabled() {
            return this.txData.coinToSell.id === this.txData.coinToBuy.id
                || this.txData.valueToSell <= 0
                || !this.sellAll && this.txData.valueToSell > this.$store.getters.balance(this.txData.coinToSell)
                || !isFinite(this.txData.valueToSell)
                || !this.txData.coinToBuy;
        },

        approximateTotal() {
            return this.exchangeRate * this.txData.valueToSell;
        },

        title() {
            return this.confirmation
                ? this.$t('coinsModal.sell.confirmation.title')
                : this.$t('coinsModal.sell.title', this.coin);
        },
    },

    watch: {
        'txData.valueToSell': {
            handler(newValue) {
                this.txData.valueToSell = filterNumber(newValue);
            },
        },

        'txData.coinToBuy': {
            immediate: false,
            handler(newValue, oldValue) {
                if (!oldValue || newValue.id === oldValue.id) {
                    return;
                }

                this.updateExchangeRates();
            },
        },

        confirmation() {
            this.error = null;
        },

        sellAll(sell_all) {
            if (! sell_all) return;

            let max = new Big(this.$store.getters.balanceBits(this.txData.coinToSell));

            if (this.tx.gasCoin == this.txData.coinToSell) {
                max = max.minus(this.commission);
            }

            this.txData.valueToSell = max.gt(0) ? max.div(10 ** 18).toString() : 0;
        },
    },

    created() {
        this.resetTxFields();
        this.availableCoins = this.$store.getters.balances.filter(({ id }) => id != this.coin.id);
        this.txData.coinToBuy = this.availableCoins[0] || defaultCoin;
        this.updateExchangeRates();
    },

    mounted() {
        this.$watch(vm => [vm.tx.gasCoin.id, vm.tx.payload, vm.txData.coinToBuy.id], debounce(function () {
            this.updateEstimatedCommission();
        }, 450), {
            immediate: true,
        });
    },

    methods: {
        updateExchangeRates() {
            const params = { ...this.txData,
                valueToSell: 10 ** 18,
            };

            estimateCoinSell(params).then(({ will_get }) => {
                this.exchangeRate = will_get / 10 ** 18;
            });
        },

        updateEstimatedCommission() {
            if (this.txData.coinToBuy) {
                Minter.estimateSellTxCommission(this.tx).then(fee => this.commission = fee);
            }
        },

        searchCoins(query) {
            const search = query.trim().toUpperCase();

            if (search.length < 2) {
                return this.availableCoins = [];
            }

            searchCoins(search).then(({ data }) => {
                this.availableCoins = data.filter(({ symbol }) => {
                    return symbol.startsWith(search) && symbol != this.coin.symbol
                });
            });
        },

        sellCoin() {
            let sdkMethodName = 'sell';
            const txData = { ...this.txData };

            if (this.sellAll) {
                sdkMethodName = 'sellAll';
                delete txData.valueToSell;
            }

            this.isLoading = true;
            Minter[sdkMethodName](this.tx, txData)
                .then(() => {
                    this.$message.success(this.$t('txCommon.messages.success'));
                    this.confirmation = false;
                    this.goBack();
                })
                .catch(this.handleSellError)
                .then(() => this.isLoading = false);
        },

        handleSellError(error) {
            const translationKey = getTranslationKeyByErrorCode(error.code);

            this.error = translationKey
                ? this.$t(translationKey)
                : error.message || error;
        },

        goBack() {
            this.confirmation
                ? this.confirmation = false
                : this.$bus.$emit('modalMyCoins:back');
        },

        updateTxFields(params) {
            Object.assign(this.tx, params);
        },

        resetTxFields() {
            this.tx = Object.assign({}, txSchema);
            this.txData = Object.assign({}, sellTxDataSchema);
            this.txData.coinToSell = this.coin;
        },
    },

    components: {
        CommonTxParams, CoinSellConfirmation,
    },
};
</script>

<template>
    <div class="d8wt__section">
        <el-select class="d8-autocomplete"
            v-bind:placeholder="$t('validator.placeholder')"
            v-if="!pubkey"
            v-model="pubkey">
            <el-option class="d8-autocomplete-item"
                v-for="item in validators"
                v-bind:key="item.pub_key"
                v-bind:label="item.name"
                v-bind:value="item.pub_key">
                    <span v-once class="d8-autocomplete-item__name" v-text="item.name"/>
                    <span v-once class="d8-autocomplete-item__pkey ui-looong"
                        v-bind:data-loopa="item.pub_key.substr(0, 60)"
                        v-bind:data-poopa="item.pub_key.substr(60)">
                    </span>
            </el-option>
        </el-select>

        <section v-else class="button-with-postfix">
            <el-button plain class="el-button button-with-postfix__left" type="primary" v-popover:validatorDropdown>
                <span class="validator-name" v-if="name" v-text="name"/>
                <span v-else class="ui-looong"
                    v-bind:data-loopa="pubkey.substr(0, 60)"
                    v-bind:data-poopa="pubkey.substr(60)"/>
                </span>
            </el-button>
            <el-button plain class="button-with-postfix__right" v-on:click="$bus.$emit('modalStake:show')">
                <ui-bips ref="stake" v-bind:key="refreshKey" v-bind:value="stake_bips"/>
            </el-button>

            <el-popover class="ui-minipopper"
                ref="validatorDropdown"
                placement="bottom"
                v-model="dropdownVisible">
                <div class="d8-popper-dropdown__container" v-on:click="dropdownVisible = false">
                    <copy-button class="el-dropdown-menu__item"
                        v-bind:text="$t('validator.menu.copy')"
                        v-bind:copy="pubkey">
                    </copy-button>
                    <el-dropdown-item v-on:click.native="$bus.$emit('modalDelegate:show')">
                        <ui-icon name="money"/> {{$t('validator.menu.delegate')}}
                    </el-dropdown-item>
                    <el-dropdown-item v-on:click.native="$bus.$emit('modalStake:show')">
                        <ui-icon name="donut_small"/> {{$t('validator.menu.stake')}}
                    </el-dropdown-item>
                    <a target="_blank" class="el-dropdown-menu__item"
                        v-bind:href="explorerAddress">
                        <ui-icon name="explorer"/> {{$t('validator.menu.openInExplorer')}}
                    </a>
                    <el-dropdown-item divided v-on:click.native="pubkey = null">
                        <ui-icon name="close"/> {{$t('validator.menu.change')}}
                    </el-dropdown-item>
                </div>
            </el-popover>
        </section>
    </div>
</template>

<script>
import CopyButton from '../UiCopyButton';
import { EXPLORER_WEB_URL } from '~/config';

export default {
    antiSuspendTimer: null,

    data() {
        return {
            dropdownVisible: false,
            refreshKey: 0,
        };
    },

    created() {
        this.$bus.$on('app:wakeup', this.refreshStakeValue);

        // TODO: КОСТЫЛЬ, ПРО НЕГО НЕ ЗАБЫТЬ
        this.$options.antiSuspendTimer = setInterval(() => {
            if (this.$refs.stake) {
                const displayed = parseFloat(this.$refs.stake.$el.innerText);
                const real = this.stake_bips;

                if (displayed == 0 && real > 0) {
                    console.error('Inoculation detected, refreshing stake');
                    this.refreshStakeValue();
                }
            }
        }, 5000);
    },

    beforeDestroy() {
        this.$bus.$off('app:wakeup', this.refreshStakeValue);
        clearInterval(this.$options.antiSuspendTimer);
    },

    computed: {
        validators() {
            return this.$store.state.validators;
        },

        name() {
            return this.$store.getters.findValidator(this.pubkey).name;
        },

        explorerAddress() {
            return `${EXPLORER_WEB_URL}/validator/${this.pubkey}`;
        },

        pubkey: {
            get() { return this.$store.state.validator.pubkey; },
            set(pubkey) { this.$store.commit('updateValidator', { pubkey }); },
        },

        stake_bips() {
            return this.$store.getters.stakes.reduce((sum, { bipValue }) => sum + bipValue, 0);
        },
    },

    methods: {
        refreshStakeValue() {
            this.refreshKey += 1;
        },
    },

    components: {
        CopyButton,
    },

    timer: null,
};
</script>

<style lang="scss">
.validator-name {
    font-weight: 600;
}
</style>

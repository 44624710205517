<template>
    <section class="active-view">
        <el-page-header class="active-view__header"
            v-bind:title="$t('common.back')"
            v-on:back="$emit('back')"
            v-bind:content="$t('delegation.confirmation.title')"/>

        <div class="active-view__body" v-loading="isLoading">
            <el-form label-position="top" class="confirmation">
                <el-form-item v-bind:label="$t('delegation.confirmation.stake')">
                    <div class="confirmation__input">
                        <div>{{stake}} {{coin.symbol}}</div>
                    </div>
                </el-form-item>
                <el-form-item v-bind:label="$t('delegation.confirmation.pubkey')">
                    <div class="confirmation__input">
                        <div v-text="publicKey"/>
                    </div>
                </el-form-item>
                <el-form-item v-bind:label="$t('common.fee') + ':'">
                    <div class="confirmation__input">
                        <div>{{commissionReadable}} {{gasCoin.symbol}}</div>
                    </div>
                </el-form-item>

                <div v-if="error" class="confirmation__disclaimer confirmation__disclaimer--error" v-text="error"/>
            </el-form>
        </div>

        <footer class="active-view__footer d8-modal-footer">
            <el-button v-if="!error" class="button--fat" type="primary"
                key="confirm_button"
                v-on:click="$emit('confirm')">
                {{$t('common.confirm')}}
            </el-button>
            <el-button v-else class="button--fat" v-on:click="$emit('back')">
                {{$t('common.back')}}
            </el-button>
        </footer>
    </section>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: () => false,
        },
        stake: {
            type: [Number, String],
            required: true,
        },
        publicKey: {
            type: String,
            required: true,
        },
        coin: {
            type: Object,
            required: true,
        },
        gasCoin: {
            type: Object,
            required: true,
        },
        commission: {
            type: String,
            required: true,
        },
        error: {
            required: false,
        },
    },

    computed: {
        commissionReadable() {
            return (this.commission / 10 ** 18).toString();
        },
    },
};
</script>

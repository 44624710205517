export default {
    login: {
        mainButton: 'Добавить кошелёк',
        signin: 'Войти',
        signup: 'Создать',
        userSeedOrPk: 'Сид-фраза или приватный ключ:',
        generatedSeed: 'Созданная сид-фраза:',
        generatedAddress: 'Ваш новый адрес:',
        disclaimer: 'Приватный ключ не передается на наши сервера и хранится только локально, в зашифрованном виде.',
        errorInvalidSeed: 'Некорректный сид',
        button: {
            createNew: 'Создать кошелёк',
            addWallet: 'Добавить',
            addViaPk: 'Добавить по приватному ключу',
            addViaSeed: 'Добавить по seed',
        },
    },

    wallet: {
        copyAddressButton: 'Копировать адрес',
        openInExplorer: 'Открыть в Explorer',
        receiveCoins: 'Получить',
        sendCoins: 'Отправить',
        myCoins: 'Мои монеты',
        logout: 'Выйти',
        logoutConfirm: {
            title: 'Выход',
            text: 'Все хранящиеся данные будут удалены. Продолжить?',
            confirmButtonText: 'Выйти',
            cancelButtonText: 'Отмена',
        },
    },

    validator: {
        placeholder: 'Выберите валидатор...',
        error: 'Ошибка загрузки списка валидаторов',
        menu: {
            copy: 'Копировать ключ',
            delegate: 'Делегировать сумму',
            stake: 'Мой стейк',
            openInExplorer: 'Открыть в Explorer',
            change: 'Выбрать другой',
        },
    },

    redelegator: {
        textDelegrating: 'Делегируем',
        textFloor: 'но не меньше',
        confirm: {
            title: 'Ределегация',
            text: 'Уверены, что хотите включить автоматическую делегацию средств?',
            ok: 'Да',
            cancel: 'Отмена',
        },
        button: {
            start: 'Запустить',
            stop: 'Остановить',
        },
    },

    delegation: {
        title: 'Делегирование',
        comissionDisclaimer: 'Комиссия спишется в BIP-ах',
        sendTx: 'Делегировать',
        form: {
            stake: 'Сумма:',
            transferAll: 'Перевести всё',
            pubkeyLabel: 'Публичный ключ:',
            pubkeyPlaceholder: 'Публичный ключ валидатора',
        },
        confirmation: {
            title: 'Подтверждение',
            stake: 'Вы делегируете:',
            pubkey: 'Публичный ключ валидатора:',
        },
    },

    txCommon: {
        messages: {
            success: 'Транзакция успешно отправлена',
        },
        form: {
            messageLabel: 'Сообщение:',
            messagePlaceholder: 'Сообщение к транзакции',
            comissionCoinLabel: 'Комиссия в:',
            chainIdLabel: 'Chain ID:', 
        },
    },

    common: {
        refresh: 'Обновить',
        close: 'Закрыть',
        nodata: 'Нет данных',
        back: 'Назад',
        confirm: 'Подтвердить',
        fee: 'Комиссия',
        useMax: 'Макс.',
        copyButton: {
            text: 'Копировать',
            success: 'Готово!',
        },
        date: {
            today: 'Сегодня',
            yesterday: 'Вчера',
        },
    },

    txHistory: {
        title: 'История транзакций',
        tabTransactions: 'Транзакции',
        tabRewards: 'Вознаграждения',
        filter: {
            all: 'Все',
            incoming: 'Входящие',
            outgoing: 'Исходящие',
        },
        // https://github.com/MinterTeam/minterjs-util/blob/master/src/tx-types.js
        types: {
            'reward': 'Награда',
            '-1':  'Отправлено',
            '1':   'Получено',
            '2':   'Продана монета',
            '3':   'Продано всё',
            '4':   'Куплена монета',
            '5':   'Создана монета',
            '6':   'Создана мастернода',
            '7':   'Делегировано',
            '8':   'Отозвано',
            '9':   'Погашен чек',
            '-9':  'Отправлено через чек',
            '10':  'Валидатор включен',
            '11':  'Валидатор отключен',
            '13':  'Получено',
            '-13': 'Отправлено',
            '14':  'Изменены настройки',
            '15':  'Голосование за блок остановки',
            '16':  'Пересоздание монеты',
            '17':  'Изменение владельца тикера',
            '18':  'Изменение мультисига',
            '19':  'Голосование за цену',
            '20':  'Редактирование адреса валидатора',
            '21':  'Добавлена ликвидность',
            '22':  'Отозвана ликвидность',
            '23':  'Продано через пул',
            '24':  'Куплено через пул',
            '25':  'Продано всё через пул',
            '26':  'Изменена комиссия',
            '27':  'Перенесён стейк',
            '28':  'Выпущен токен',
            '29':  'Сожжён токен',
            '30':  'Повторно создан токен',
            '31':  'Голосование за комиссию',
            '32':  'Голосование за обновление',
            '33':  'Создан пул',
        },
        multisend: {
            toAddresses: '{0} адресов',
            coinCount: 'Монет: {0}',
        },
        gross: 'Итого',
        loadMore: 'Загрузить ещё',
        info: {
            hash: 'Хеш',
            date: 'Дата',
            you: 'Вы',
            amount: 'Сумма',
            sender: 'Отправитель',
            receiver: 'Получатель',
            coinSold: 'Продано',
            coinReceived: 'Получено',
            coinName: 'Описание монеты',
            coinSymbol: 'Тикер',
            coinInitialAmount: 'Эмиссия',
            coinInitialReserve: 'Резерв',
            coinCRR: 'CRR',
            payload: 'Сообщение',
            nopayload: 'Пусто',
            moreCoins: 'и ещё {0} монет',
            addedLiquidity: 'Изменение ликвидности',
            lpAmount: 'Получено токенов пула',
        },
        errorMessage: 'Ошибка загрузки статистики',
        notification: {
            title: 'Пришли деньги',
        },
    },

    stakeModal: {
        title: 'Мой стейк',
        actionUnbond: 'Отозвать',
        unbond: {
            title: 'Отзыв стейка',
            form: {
                stake: 'Сумма:',
                submit: 'Отозвать',
            },
            confirmation: {
                title: 'Подтверждение',
                stake: 'Вы отзываете:',
                pubkey: 'Из валидатора:',
                warning: 'Отозванные монеты вернутся на ваш кошелек примерно через 30 дней (518 400 блоков) и не будут приносить награды в течение этого времени.',
            },
        },
    },

    coinsModal: {
        title: 'Монеты',
        tabs: {
            wallet: 'В кошельке',
            buy: 'Купить',
        },
        buy: {
            title: 'Покупка {symbol}',
            form: {
                amount: 'Сколько покупаем:',
                coinFrom: 'Что тратим:',
                approximateTotal: 'Вы заплатите примерно:',
                submit: 'Купить',
            },
            search: {
                placeholder: 'Поиск монет',
                tip: 'Начните вводить название монеты',
                actionPurchase: 'Купить',
            },
            confirmation: {
                title: 'Подтверждение покупки',
                amountLabel: 'Вы покупаете:',
                disclaimer: 'Итоговая сумма зависит от курса непосредственно в момент покупки и может отличаться от представленной выше.',
            },
        },
        sell: {
            title: 'Обмен {symbol}',
            form: {
                amount: 'Сколько продаём:',
                coinTo: 'Какую монету получаем:',
                approximateTotal: 'Получится примерно:',
                submit: 'Продать',
                sellAll: 'Продать всё',
            },
            confirmation: {
                title: 'Подтверждение продажи',
                amountLabel: 'Вы продаёте:',
                approximateTotal: 'Вы получите примерно:',
                disclaimerSellAll: 'Будут потрачены все доступные монеты {0}.',
                disclaimer: 'Итоговая сумма зависит от курса непосредственно в момент продажи и может отличаться от представленной выше.',
            },
        },
    },

    receiveModal: {
        title: 'Получить монеты',
    },

    sendModal: {
        title: 'Отправить монеты',
        form: {
            receiver: 'Получатель:',
            receiverPlaceholder: 'Адрес или домен',
            amount: 'Сумма:',
            submit: 'Отправить',
        },
        confirmation: {
            title: 'Отправка монет',
            amount: 'Вы отправляете:',
            receiver: 'На адрес:',
        },
        errors: {
            unresolvableDomain: 'Домен не найден',
            invalidAddress: 'Некорректный адрес',
        },
    },

    settings: {
        title: 'Настройки',
        altCurrency: 'Альтернативная валюта:',
        language: 'Язык (Language):',
        app: 'Приложение:',
        reportErrors: 'Сообщать об ошибках',
        showNotifications: 'Уведомления на рабочем столе',
        appVersion: 'Версия приложения',
        installToChrome: 'Установить в Chrome',
        currencies: {
            usd: 'Доллар США',
            rub: 'Российский рубль',
            eur: 'Евро',
            cny: 'Китайский Юань',
            btc: 'Биткоин',
            bch: 'Bitcoin Cash',
            eth: 'Эфир',
        },
        buttons: {
            telegram: 'Мы в Telegram',
            support: 'Поддержка',
        },
    },

    electron: {
        update: {
            check: 'Проверить обновления',
            available: 'Доступно обновление',
            unavailable: 'Нет обновлений',
            downloaded: 'Обновление будет установлено после перезапуска',
            checking: 'Запрос обновления',
            error: 'Ошибка загрузки обновления',
        },
    },

    pwa: {
        text: 'Установите ределегатор в Chrome, чтобы запускать его как обычное приложение',
        install: 'Установить',
        dismiss: 'Нет, спасибо',
        help: 'Вы в любой момент можете установить приложение в Chrome в настройках кошелька.',
        okay: 'Хорошо',
    },

    errors: {
        insufficient_funds: 'Недостаточно средств на счету. Выберите другую сумму или смените монету для оплаты комиссии.',
        insufficient_stake: 'Для анбонда выбран слишком большой стейк.',
        coin_supply_overflow: 'Достигнут максимальный резерв монеты.',
    },
};

<template>
    <div class="tx-history-item">
        <span class="tx-history-item__image">
            <img v-if="avatar" class="tx-history-item__avatar" v-bind:src="avatar">

            <span v-else class="tx-history-item__icon">
                <ui-icon v-bind:name="icon"/>
            </span>

            <span v-if="avatar && show_aux && icon" class="tx-history-item__aux">
                <ui-icon v-bind:name="icon"/>
            </span>
        </span>

        <div class="tx-history-item__info" style="max-width: 50%;">
            <span class="tx-history-item__type" v-text="$t('txHistory.types.' + txType)"/>
            <span class="tx-history-item__reward">
                <span v-if="txType == 2 || txType == 3 || txType == 4 || txType == 23 || txType == 24">
                    {{txData.coin_to_sell.symbol}} → {{txData.coin_to_buy.symbol}}
                </span>

                <!-- CREATE_COIN -->
                <span v-else-if="txType == 5" v-text="txData.symbol"/>

                <!-- Исходящий мультисенд -->
                <span v-else-if="txType == -13" class="tx-history-item-address">
                    <span class="tx-history-item-address__collapsed"
                        v-text="$t('txHistory.multisend.toAddresses', [ txData.list.length ])"/>
                </span>

                <!-- ADD_LIQUIDITY 0x15, REMOVE_LIQUIDITY 0x16 -->
                <span v-else-if="txType == 21 || txType == 22">
                    {{txData.coin0.symbol}} / {{txData.coin1.symbol}}
                </span>

                <div v-else class="tx-history-item-address">
                    <span v-once
                        v-if="txContactName"
                        class="tx-history-item-address__readable"
                        v-text="txContactName"/>
                    <span v-once v-else
                        class="ui-looong tx-history-item-address__collapsed"
                        v-bind:data-loopa="txAddress.substr(0, txAddress.length - 6)"
                        v-bind:data-poopa="txAddress.substr(txAddress.length - 6)">
                    </span>
                </div>
            </span>
        </div>

        <div v-if="canShowTxTotal" class="tx-history-item-amount">
            <span class="tx-history-item-amount__value"
                v-text="displayedAmount"
                v-bind:class="{
                    'tx-history-item-amount__value--income': isIncome,
                    'tx-history-item-amount__value--expend': isExpend,
                }"/>
            <div class="tx-history-item-amount__coin" v-text="displayedCoin.symbol"/>
        </div>

        <!-- Исходящий мультисенд  -->
        <div v-else-if="txType == -13" class="tx-history-item-amount">
            <span v-if="txData.coinCount == 1">
                <span class="tx-history-item-amount__value tx-history-item-amount__value--expend"
                    v-text="displayedAmount"/>
                <div class="tx-history-item-amount__coin"
                    v-text="displayedCoin.symbol"/>
            </span>
            <span v-else>
                <span class="tx-history-item-amount__value">···</span>
                <div class="tx-history-item-amount__coin" v-text="$t('txHistory.multisend.coinCount', [
                    txData.coinCount,
                ])"/>
            </span>
        </div>
    </div>
</template>

<script>
import { TX_TYPE } from '~/adapters/minter/Constants';
import { getAvatarUrl, parseOutgoingMultisendTx } from '~/helpers';
import { isCoinObject } from '~/adapters/minter/utils';

export default {
    props: {
        coin: {
            type: Object,
            required: false,
            validator: isCoinObject,
        },
        amount: {
            required: false,
        },
        nonce: Number,
        timestamp: String,
        type: Number,
        from: String,
        data: Object,
    },

    computed: {
        address() {
            return this.$store.state.wallet.address;
        },

        fromThis() {
            return this.txTypeHex == TX_TYPE.REDEEM_CHECK
                ? this.data.check.sender == this.address
                : this.from == this.address;
        },

        isExpend() {
            return [-1, 2, 3, 6, 8, -9, -13, 22, 23].includes(this.txType);
        },

        isIncome() {
            if ([TX_TYPE.BUY_SWAP_POOL, TX_TYPE.ADD_LIQUIDITY].indexOf(this.txTypeHex) >= 0) {
                return true;
            }

            return !this.fromThis && [
                TX_TYPE.SEND,
                TX_TYPE.BUY,
                TX_TYPE.CREATE_COIN,
                TX_TYPE.REDEEM_CHECK,
                TX_TYPE.MULTISEND,
            ].indexOf(this.txTypeHex) >= 0;
        },

        canShowTxTotal() {
            return [-1, 1, 2, 3, 4, 5, 6, 7, 8, 9, -9, 13, 21, 22, 23, 24].indexOf(this.txType) >= 0;
        },

        txData() {
            if (this.txTypeHex == TX_TYPE.MULTISEND) {
                return this.isIncome
                    ? this.data.list.find(tx => tx.to == this.address)
                    : parseOutgoingMultisendTx(this.data);
            }

            return this.data;
        },

        txTypeHex() {
            return this.type.toString(16).toUpperCase().padStart(4, '0x');
        },

        txType() {
            if ([TX_TYPE.SEND, TX_TYPE.REDEEM_CHECK, TX_TYPE.MULTISEND].includes(this.txTypeHex)) {
                return this.type * (this.fromThis ? -1 : 1);
            }

            return this.type;
        },

        txAddress() {
            return this.txData.pub_key
                || (this.txData.check && (this.fromThis ? this.from : this.txData.check.sender))
                || (this.fromThis && this.txData.to)
                || this.from;
        },

        txContactName() {
            const validatorRelatedTxTypes = [
                TX_TYPE.DECLARE_CANDIDACY,
                TX_TYPE.DELEGATE,
                TX_TYPE.UNBOND,
                TX_TYPE.SET_CANDIDATE_ON,
            ];

            if (validatorRelatedTxTypes.includes(this.txTypeHex)) {
                return this.$store.getters.findValidator(this.txData.pub_key).name;
            }
        },

        displayedCoin() {
            switch (this.txTypeHex) {
                case TX_TYPE.SEND:
                case TX_TYPE.DELEGATE:
                case TX_TYPE.DECLARE_CANDIDACY:
                case TX_TYPE.UNBOND:
                case TX_TYPE.MULTISEND:
                    return this.txData.coin;
                    break;

                case TX_TYPE.SELL:
                case TX_TYPE.SELL_SWAP_POOL:
                case TX_TYPE.SELL_ALL:
                case TX_TYPE.SELL_ALL_SWAP_POOL:
                    return this.txData.coin_to_sell;
                    break;

                case TX_TYPE.BUY:
                case TX_TYPE.BUY_SWAP_POOL:
                    return this.txData.coin_to_buy;
                    break;

                case TX_TYPE.CREATE_COIN:
                    return Object.freeze({
                        symbol: this.txData.symbol,
                        id: -1,
                    });
                    break;

                case TX_TYPE.REDEEM_CHECK:
                    return this.txData.check.coin;
                    break;

                case TX_TYPE.ADD_LIQUIDITY:
                case TX_TYPE.REMOVE_LIQUIDITY:
                    return this.txData.pool_token;
                    break;

                default:
                    return {};
                    break;
            }
        },

        displayedAmount() {
            let shortened = this.txData.value
                || this.txData.value_to_buy
                || this.txData.stake
                || this.txData.initial_amount
                || (this.txData.check && this.txData.check.value)
                || this.txData.liquidity;

            return parseFloat(shortened).toFixed(2);
        },

        avatar() {
            if ([
                TX_TYPE.SELL,
                TX_TYPE.SELL_ALL,
                TX_TYPE.BUY,
                TX_TYPE.CREATE_COIN,
                TX_TYPE.SELL_SWAP_POOL,
                TX_TYPE.BUY_SWAP_POOL,
                TX_TYPE.ADD_LIQUIDITY,
                TX_TYPE.REMOVE_LIQUIDITY,
            ].includes(this.txTypeHex)) {
                return null;
            }

            if ([6, 7, 8, 10, 11, 14].indexOf(this.type) >= 0) {
                return this.$store.getters.findValidator(this.txData.pub_key).icon_url;
            }

            return getAvatarUrl(this.txAddress);
        },

        show_aux() {
            return [
                TX_TYPE.DECLARE_CANDIDACY,
                TX_TYPE.UNBOND,
                TX_TYPE.SET_CANDIDATE_ON,
                TX_TYPE.SET_CANDIDATE_OFF,
                TX_TYPE.MULTISEND,
            ].indexOf(this.txTypeHex) >= 0;
        },

        icon() {
            switch (this.txTypeHex) {
                case TX_TYPE.SELL:               return 'sync';
                case TX_TYPE.SELL_ALL:           return 'sync';
                case TX_TYPE.BUY:                return 'sync';
                case TX_TYPE.CREATE_COIN:        return 'add_circle_outline';
                case TX_TYPE.DECLARE_CANDIDACY:  return 'settings';
                case TX_TYPE.DELEGATE:           return 'save_alt';
                case TX_TYPE.UNBOND:             return 'open_in_app';
                case TX_TYPE.SET_CANDIDATE_ON:   return 'power_plug';
                case TX_TYPE.SET_CANDIDATE_OFF:  return 'power_plug_off';
                case TX_TYPE.CREATE_MULTISIG:    return 'supervised_user_circle';
                case TX_TYPE.MULTISEND:          return 'wifi_tethering';
                case TX_TYPE.EDIT_CANDIDATE:     return 'settings';
                case TX_TYPE.SELL_SWAP_POOL:     return 'swap_horizontal_circle';
                case TX_TYPE.BUY_SWAP_POOL:      return 'swap_horizontal_circle';
                case TX_TYPE.ADD_LIQUIDITY:      return 'control_point_duplicate';
                case TX_TYPE.REMOVE_LIQUIDITY:   return 'control_point_remove';
                default: return null;
            };
        },
    },
};
</script>

<style lang="scss">
.tx-history-item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 12px 18px;
    box-sizing: border-box;
    width: 100%;
    line-height: 1;
    color: #303133;
    &__image {
        font-size: 0;
        margin: 0 0 0 -4px;
        position: relative;
        z-index: 100;
    }
    &__avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }
    &__icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        background: #EEE;
        svg {
            fill: #5c40d2;
        }
    }
    &__aux {
        position: absolute;
        background: #FFF;
        padding: 2px;
        border-radius: 50%;
        bottom: -2px;
        right: -4px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        svg {
            width: 14px;
            height: 14px;
            fill: #5c40d2;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        padding: 0 0 0 10px;
        flex-shrink: 1;
        min-width: 50%;
    }
    &__type {
        font-weight: 500;
        color: #bebebe;
        font-size: 12px;
        line-height: 1;
        padding: 0 0 6px 0;
    }
    &__reward {
        font-size: 16px;
        font-weight: 600;
        color: #555;
    }
}
.tx-history-item-address {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &__collapsed {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    &__readable {
        font-size: 16px;
        line-height: 18px;
        color: #333;
        font-weight: 500;
    }
}
.tx-history-item-amount {
    padding-left: 16px;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    align-self: self-end;
    text-align: right;
    font-weight: 500;
    font-size: 0;
    min-width: 60px;
    &__value {
        color: inherit;
        font-size: 18px;
        &--income {
            color: #02bf0e;
            &::before {
                content: '+ ';
            }
        }
        &--expend {
            &::before {
                content: '− ';
            }
        }
    }
    &__coin {
        padding: 4px 0 2px 0;
        font-size: 12px;
        color: #929292;
    }
}
</style>

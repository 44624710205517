<template>
    <el-dialog class="wallet-dialog el-dialog--no-pad" top="20px" v-bind:visible.sync="isVisible">
        <section v-show="!confirmation" class="active-view">
            <el-page-header class="active-view__header"
                v-bind:title="$t('common.back')"
                v-on:back="isVisible = false"
                v-bind:content="$t('delegation.title')"/>

            <el-form label-position="top" class="active-view__body">
                <el-form-item v-bind:label="$t('delegation.form.stake')">
                    <ui-coin-input show-max
                        v-bind:gasCoin="tx.gasCoin"
                        v-bind:value="txData.stake"
                        v-bind:coin="txData.coin"
                        v-bind:commission="commission"
                        v-on:valueUpdated="(stake) => txData.stake = stake"
                        v-on:coinUpdated="(coin) => txData.coin = coin"/>
                    <div v-if="error" v-text="error" class="el-form-item__error"/>
                </el-form-item>

                <section class="d8-action-modal__extras" v-show="advancedVisible">
                    <el-form-item v-bind:label="$t('delegation.form.pubkeyLabel')">
                        <el-input type="textarea" rows="2"
                            autocorrect="off" data-gramm="false" autocapitalize="off" spellcheck="false"
                            v-bind:maxlength="66"
                            v-bind:placeholder="$t('delegation.form.pubkeyPlaceholder')"
                            v-model="txData.publicKey">
                        </el-input>
                    </el-form-item>
                    <common-tx-params v-bind="tx" v-on:update="updateTxParams"/>
                </section>
            </el-form>

            <footer class="active-view__footer d8-modal-footer">
                <el-button class="button--fat" type="primary"
                    v-bind:disabled="isDisabled"
                    v-on:click="confirmation = true">
                    {{$t('delegation.sendTx')}}
                </el-button>
                <el-button type="text" class="d8-modal-footer__settings"
                    v-on:click="advancedVisible = !advancedVisible">
                    <ui-icon name="settings"/>
                </el-button>
            </footer>
        </section>

        <delegate-confirmation v-if="confirmation"
            v-on:back="confirmation = false"
            v-on:confirm="delegate()"
            v-bind:commission="commission"
            v-bind:isLoading="isLoading"
            v-bind:gasCoin="tx.gasCoin"
            v-bind:error="error"
            v-bind="txData"/>
    </el-dialog>
</template>

<script>
import { filterNumber, getTranslationKeyByErrorCode } from '~/helpers';
import { delegateTxDataSchema, txSchema } from '~/adapters/minter/Schema';
import DelegateConfirmation from './ModalActionDelegateConfirmation';
import CommonTxParams from './ModalPartialTxCommon';
import Minter from '~/adapters/minter';
import debounce from 'lodash.debounce';
import Big from 'big.js';

export default {
    data() {
        return {
            isVisible: false,
            isLoading: false,
            advancedVisible: false,
            confirmation: false,
            commission: 0,
            error: null,
            delegateAmountBackup: 0,
            tx: {},
            txData: {},
        };
    },

    computed: {
        balances() {
            return this.$store.getters.balances;
        },

        currentBalance() {
            return this.$store.getters.balance(this.txData.coin);
        },

        commissionReadable() {
            return (this.commission / 10 ** 18).toString();
        },

        isDisabled() {
            return this.txData.stake <= 0 || !isFinite(this.txData.stake);
        },
    },

    watch: {
        isVisible(visible) {
            if (! visible) {
                this.isLoading = false;
                this.advancedVisible = false;
                this.confirmation = false;
                this.error = null;
            } else {
                // Нужно, потому что при смене валидатора он не сменяется в форме.
                this.resetTxFields();
            }
        },

        confirmation() {
            this.error = null;
        },

        'txData.coin': {
            handler() {
                this.error = null;
            },
        },

        'txData.stake': {
            handler(value) {
                this.txData.stake = filterNumber(value);
            },
        },
    },

    created() {
        this.$bus.$on('modalDelegate:show', () => this.isVisible = true);
        this.resetTxFields();
    },

    mounted() {
        this.$watch(vm => [vm.txData.coin.id, vm.tx.gasCoin.id, vm.tx.payload], debounce(function () {
            this.updateEstimatedCommission();
        }, 450), {
            immediate: true,
        });
    },

    beforeDestroy() {
        this.$bus.$off('modalDelegate:show');
    },

    methods: {
        delegate() {
            this.isLoading = true;
            Minter.delegate(this.tx, this.txData)
                .then(() => {
                    this.$message.success(this.$t('txCommon.messages.success'));
                    this.isVisible = false;
                })
                .catch(this.handleDelegateError)
                .then(() => this.isLoading = false);
        },

        handleDelegateError(error) {
            const translationKey = getTranslationKeyByErrorCode(error.code);
            this.error = translationKey
                ? this.$t(translationKey)
                : error.message || error;
        },

        updateEstimatedCommission() {
            Minter.estimateDelegateTxCommission(this.tx).then(fee => this.commission = fee);
        },

        updateTxParams(params) {
            Object.assign(this.tx, params);
        },

        resetTxFields() {
            this.tx = Object.assign({}, txSchema);
            this.txData = Object.assign({}, delegateTxDataSchema);
            this.txData.publicKey = this.$store.state.validator.pubkey;
        },
    },

    components: {
        DelegateConfirmation,
        CommonTxParams,
    },
};
</script>

<style lang="scss">
.active-view-input {
    .el-input-group__append {
        min-width: 33%;
        min-width: 110px;
        box-sizing: border-box;
    }
}
.d8-modal-footer {
    display: flex;
    &__settings {
        flex-grow: 0;
        padding: 14px;
        margin: -4px -14px -4px -2px !important;
        svg {
            fill: #dcdfe6;
        }
        span {
            display: block;
            font-size: 0;
        }
        &:hover svg {
            fill: #0069ff;
        }
    }
}
.d8-action-modal {
    &__extras {
        padding-top: 18px;
    }
    &__form {
        width: 100%;
        label {
            line-height: 20px;
        }
    }
    &__input-suggestion {
        position: absolute;
        bottom: -16px;
        left: 0px;
        font-size: 12px;
        color: #c9c9c9;
        font-weight: 600;
        line-height: 1;
        margin-top: 4px;
        margin-left: 1px;
        cursor: pointer;
        &:hover {
            color: #777;
        }
    }
    &__alert {
        margin-top: 34px;
        margin-bottom: -4px;
    }

    .el-dialog__body {
        padding-bottom: 16px;
        padding-top: 24px;
    }
    .el-input--suffix {
        min-width: 100px;
    }
}

.d8-dd-balances {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__ticker {
        font-weight: 500;
        margin-right: 2ex;
    }
    &__balance {
        font-weight: normal;
        color: #8492a6;
        font-size: 0.9em;
        margin-top: -2px;
    }
}
</style>

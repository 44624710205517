import { EXPLORER_RTM_URL, APP_ENV } from '~/config';
import MinterNodeApi from '~/adapters/minter/NodeApi';
import { debugWrite } from '~/logging';
import Centrifuge from 'centrifuge';

const debugLog = (...args) => debugWrite('Balances', ...args);

let centrifuge;
let subscription;

export default {
    $store: null,

    init({ $store, $bus }) {
        this.$store = $store;

        this.connect();
        this.subscribe();

        this.dispatchUpdateBalanceAction();
        $bus.$on('app:wakeup', () => this.wakeUp());

        $store.watch((state) => state.wallet.address, (value) => {
            this.subscribe();
            this.dispatchUpdateBalanceAction();
        });

        $store.watch((state) => state.wallet.balances, (now, was) => {
            if (! $store.state.wallet.address) {
                return;
            }

            // Нулевой индекс = бипы. Костыль, TODO:
            $bus.$emit('balanceUpdated', {
                bipsChanged: now[0].symbol == 'BIP' && now[0].bits != was[0].bits,
                balance: now[0].balance,
                address: $store.state.wallet.address,
            });
        });
    },

    connect() {
        centrifuge = new Centrifuge(EXPLORER_RTM_URL, {
            debug: APP_ENV == 'development',
        });

        centrifuge.connect();
    },

    subscribe() {
        const address = this.$store.state.wallet.address;

        if (subscription) {
            subscription.unsubscribe();
            debugLog('Unsubscribed from old address');

            if (! address) {
                subscription = null;
            }
        }

        if (address && centrifuge) {
            subscription = centrifuge.subscribe(address, () => this.dispatchUpdateBalanceAction());
            debugLog('Centrifuge subscribed');
        }
    },

    destroy() {
        if (centrifuge) {
            centrifuge.disconnect();
            centrifuge = null;
        }
    },

    wakeUp() {
        debugLog('Waking up balance subscriptions');

        if (! centrifuge) {
            this.connect();
        }

        if (! subscription) {
            this.subscribe();
        }

        this.dispatchUpdateBalanceAction();
    },

    dispatchUpdateBalanceAction() {
        if (this.$store.state.wallet.address) {
            this.$store.dispatch('UPDATE_BALANCE');
        }
    },

    updateBalance() {
        if (this.$store.state.wallet.address) {
            return centrifuge && centrifuge.isConnected()
                ? debugLog('Balance is synced from Explorer RTM')
                : this.dispatchUpdateBalanceAction();
        }
    },
}

<template>
    <div class="d8wt__section">
        <section class="button-with-postfix">
            <el-button plain class="button-with-postfix__left" type="primary" v-popover:walletDropdown>
                <span v-once class="ui-looong" style="font-weight: 400"
                    v-bind:data-loopa="address.substr(0, 36)"
                    v-bind:data-poopa="address.substr(36)">
                </span>
            </el-button>
            <el-button plain class="button-with-postfix__right" v-on:click="$bus.$emit('modalMyCoins:show')">
                <ui-bips v-bind:value="balance"/>
            </el-button>
        </section>

        <el-popover class="ui-minipopper"
            ref="walletDropdown"
            placement="bottom"
            v-model="dropdownVisible">
            <div class="d8-popper-dropdown__container" v-on:click="dropdownVisible = false">
                <copy-button class="el-dropdown-menu__item"
                    v-bind:text="$t('wallet.copyAddressButton')"
                    v-bind:copy="address">
                </copy-button>
                <el-dropdown-item v-on:click.native="$bus.$emit('modalSendCoins:show')">
                    <ui-icon v-once name="custom_send"/>
                    {{$t('wallet.sendCoins')}}
                </el-dropdown-item>
                <el-dropdown-item v-on:click.native="$bus.$emit('modalReceiveCoins:show')">
                    <ui-icon v-once name="play_for_work"/>
                    {{$t('wallet.receiveCoins')}}
                </el-dropdown-item>
                <el-dropdown-item v-on:click.native="$bus.$emit('modalMyCoins:show')">
                    <ui-icon v-once name="category"/>
                    {{$t('wallet.myCoins')}}
                </el-dropdown-item>
                <a class="el-dropdown-menu__item" v-bind:href="explorerAddress">
                    <ui-icon v-once name="explorer"/>
                    {{$t('wallet.openInExplorer')}}
                </a>
                <el-dropdown-item divided v-on:click.native="deleteWallet()">
                    <ui-icon v-once name="power"/>
                    {{$t('wallet.logout')}}
                </el-dropdown-item>
            </div>
        </el-popover>
    </div>
</template>

<script>
import CopyButton from '../UiCopyButton';
import { EXPLORER_WEB_URL } from '~/config';

export default {
    data() {
        return {
            dropdownVisible: false,
        };
    },

    computed: {
        address() {
            return this.$store.state.wallet.address;
        },

        balance() {
            return this.$store.getters.bipbalance;
        },

        explorerAddress() {
            return `${EXPLORER_WEB_URL}/address/${this.address}`;
        },
    },

    created() {
        this.$bus.$on('balanceUpdated', () => this.$forceUpdate());
    },

    methods: {
        deleteWallet() {
            this.$confirm(
                this.$t('wallet.logoutConfirm.text'),
                this.$t('wallet.logoutConfirm.title'), {
                    confirmButtonText: this.$t('wallet.logoutConfirm.confirmButtonText'),
                    cancelButtonText: this.$t('wallet.logoutConfirm.cancelButtonText'),
                })
            .then(() => this.$store.commit('resetWallet'))
            .catch(console.debug);
        },
    },

    components: {
        CopyButton,
    },
};
</script>

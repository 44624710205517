import { TX_TYPE } from 'minter-js-sdk/dist/cjs';

/**
 * @see https://github.com/MinterTeam/minter-go-node/blob/2e6614d5d0ab9c434ee3da8cb5450f21a2c78feb/core/code/code.go#L8
 */
export const ERROR = Object.freeze({
    'INSUFFICIENT_FUNDS': 107,
    'INSUFFICIENT_STAKE': 405,
    'COIN_SUPPLY_OVERFLOW': 112,
});

export {
    TX_TYPE,
};

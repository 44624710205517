<template>
    <header class="tx-history-separator" v-text="readableDate"/>
</template>

<script>
export default {
    props: {
        date: {
            type: String,
        },
    },

    computed: {
        readableDate() {
            const now = Date.now();
            const date = new Date(this.date);

            if (now - date.getTime() <= 24 * 60 * 60 * 1000) {
                return this.$t('common.date.today');

            } else if (now - date.getTime() <= 48 * 60 * 60 * 1000) {
                return this.$t('common.date.yesterday');
            }

            return date.toLocaleString(this.$i18n.locale, {
                month: 'long', day: 'numeric', weekday: 'long',
            });
        },
    },
};
</script>

<style lang="scss">
.tx-history-separator {
    padding: 12px 0 12px 16px;
    margin: 14px 0 0 0;
    border-bottom: 1px solid #ebeef5;
    color: #444;
    cursor: default;
    user-select: none;
    display: block;
    color: inherit;
    text-align: left;
    box-sizing: border-box;
    font-weight: 500;
    line-height: inherit;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    background: #FFF;
    font-size: 12px;
    z-index: 200;
}
</style>

import { COINGECKO_API_URL, BIP_VS_CURRENCIES } from '~/config';
import { defaultCoin } from '~/adapters/minter/Schema';
import MinterNodeApi from '~/adapters/minter/NodeApi';
import MinterExplorerApi from '~/adapters/minter/ExplorerApi';
import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        wallet: {
            address: null,
            phonebook: {
                recentLastBlock: 0,
                recentAddresses: [],
            },
            balances: [{
                ...defaultCoin,
                balance: 0,
                bits: '0',
            }],
            stakes: [{
                coin: { ...defaultCoin },
                value_exact: '0',
                bipValue: 0,
                value: 0,
                pub_key: '',
                isWaitlisted: false,
            }],
        },
        validators: [],
        validator: {
            pubkey: '',
        },
        settings: {
            percent: 100,
            floor: 0,
            currency: 'USD',
            language: 'ru',
            reportErrors: true,
            pwaDismissed: false,
            notifications: true,
        },
        exchangeRates: [{
            symbol: 'USD',
            rate: 0,
        }],
        services: {
            notifications: {
                enabled: true,
                allowed: false,
                supported: true,
                lastTxNumber: 0,
            },
            autodelegator: {
                isRunning: false,
            },
            stakeUpdater: {
                loading: true,
                error: false,
            },
        },
    },

    mutations: {
        setStakeUpdaterStatus(state, { loading, error = false }) {
            Object.assign(state.services.stakeUpdater, { loading, error });
        },

        setAutodelegatorStatus(state, isRunning) {
            state.services.autodelegator.isRunning = isRunning;
        },

        updateBalance(state, balances) {
            state.wallet.balances = balances;
        },

        updateValidators(state, validators) {
            state.validators = validators;
        },

        updateStakes(state, stakes) {
            state.wallet.stakes = stakes;
        },

        updateRecentAddresses(state, { list, height }) {
            stake.wallet.phonebook.recentLastBlock = height;
            state.wallet.phonebook.recentAddresses = list;
        },

        incrementRecentAddressFrequency({ wallet: { phonebook }}, address) {
            const entry = phonebook.recentAddresses.find((item) => item.address === address);

            entry !== undefined
                ? entry.count += 1
                : phonebook.recentAddresses.push({ address, count: 1 });

            const sorted = phonebook.recentAddresses.sort((a, b) => b.count - a.count);

            phonebook.recentAddresses = sorted;
        },

        updateRecentAddressesLastBlock({ wallet: { phonebook }}, height) {
            phonebook.recentLastBlock = height;
        },

        updateLastTxn(state, txn) {
            state.services.notifications.lastTxNumber = txn;
        },

        updateValidator(state, { pubkey }) {
            state.validator.pubkey = pubkey;
        },

        updateSettings(state, settings) {
            Object.assign(state.settings, { ...settings });
        },

        updateWallet(state, { address }) {
            state.wallet.address = address;
        },

        resetWallet(state) {
            state.wallet.address = null;
            state.wallet.balances = [];
            state.wallet.stakes = [];
            state.wallet.recentAddresses = [];
        },

        updateExchangeRates(state, rates) {
            state.exchangeRates = rates;
        },

        updateDefaultCurrency(state, value) {
            state.settings.currency = value;
        },

        updateNotificationServiceConfig(state, settings) {
            Object.assign(state.services.notifications, { ...settings });
        },
    },

    actions: {
        UPDATE_EXCHANGE_RATES({ commit, state }) {
            const params = {
                ids: 'BIP',
                vs_currencies: BIP_VS_CURRENCIES.join(','),
            };

            this._vm.$http
                .get(COINGECKO_API_URL + '/simple/price', { params })
                .then(({ data: { bip: rates }}) => {
                    const result = BIP_VS_CURRENCIES.map((symbol) => {
                        const rate = rates[symbol.toLowerCase()];
                        return { symbol, rate };
                    });

                    return commit('updateExchangeRates', result);
            });
        },

        UPDATE_BALANCE({ commit, state }) {
            MinterNodeApi.getBalance(state.wallet.address).then(({ balance }) => {
                const coins = balance.map((item) => ({
                    id: parseInt(item.coin.id),
                    symbol: item.coin.symbol,
                    isPoolToken: item.coin.isPoolToken,
                    // Не учитываем дробную часть дальше 5 знака после запятой,
                    // чтобы число не преобразовалось в экспоненциальный вид:
                    balance: parseFloat((item.value / 10 ** 18).toFixed(5)),
                    bipValue: parseFloat((item.bip_value / 10 ** 18).toFixed(5)),
                    bits: item.value,
                }));

                // Сортируем по балансу, если баланс одинаковый, то по алфавиту:
                coins.sort((a, b) => {
                    return b.balance == a.balance
                        ? (a.symbol < b.symbol ? -1 : 1)
                        : Math.sign(b.balance - a.balance);
                });

                // Ставим BIP выше всех монет:
                coins.sort(a => a.symbol !== defaultCoin.symbol);

                // Ставим токены пулов в самый низ:
                coins.sort(a => a.isPoolToken);

                commit('updateBalance', coins);
            });
        },

        UPDATE_STAKES({ commit, dispatch, state }) {
            commit('setStakeUpdaterStatus', {
                loading: true,
                error: false,
            });

            MinterExplorerApi.getDelegations(state.wallet.address)
                .then((delegations) => {
                    const stakes = delegations.map((stake) => ({
                        coin: Object.freeze(stake.coin),
                        value_exact: stake.value,
                        value: parseFloat(stake.value),
                        bipValue: parseFloat(stake.bip_value),
                        pub_key: stake.validator.public_key,
                        isWaitlisted: stake.is_waitlisted,
                    }));

                    // TODO: meta.additional.total_delegated_bip_value

                    stakes.sort((a, b) => a.coin.symbol < b.coin.symbol ? -1 : 1);
                    stakes.sort((a, b) => b.coin.id == defaultCoin.id);

                    commit('updateStakes', stakes);
                    dispatch('UPDATE_VALIDATORS');
                })
                .catch((error) => {
                    commit('setStakeUpdaterStatus', { error: true });
                })
                .then(() => {
                    commit('setStakeUpdaterStatus', { loading: false });
                });
        },

        UPDATE_VALIDATORS({ commit, state }) {
            MinterExplorerApi.getValidators().then((data) => {
                const validators = data
                    .filter(({ status, part }) => status === 2 && parseFloat(part) > 0)
                    .map((validator) => {
                        const userStake = state.wallet.stakes
                            .filter((item) => item.pub_key === validator.public_key)
                            .reduce((sum, { bip_value }) => sum + bip_value, 0);

                        return { ...validator, userStake };
                    });

                validators.sort((a, b) => Math.sign(b.voting_power - a.voting_power));
                validators.sort((a, b) => Math.sign(b.userStake - a.userStake))

                commit('updateValidators', validators);
            });
        },

        REFRESH_RECENT_ADDRESSES({ commit, state }) {
            const address = state.wallet.address;

            MinterExplorerApi
                .getTransactions(address)
                .then(({ data: transactions }) => {
                    transactions
                        .filter(({ height }) => {
                            return height > state.wallet.phonebook.recentLastBlock;
                        })
                        .flatMap(({ from, data }) => {
                            return from == address
                                ? data.to || []
                                : from;
                        })
                        .forEach((address) => {
                            commit('incrementRecentAddressFrequency', address);
                        });

                    if (transactions.length > 0) {
                        commit('updateRecentAddressesLastBlock', transactions[0].height);
                    }
                });
        },
    },

    getters: {
        balances ({ wallet: { balances }}) {
            return balances;
        },

        balance: ({ wallet: { balances }}) => (search) => {
            const id = typeof search === 'object'
                ? search.id
                : search;

            const item = balances.find(coin => coin.id === id);
            return item ? item.balance : 0;
        },

        balanceBits: ({ wallet: { balances }}) => (search) => {
            const id = typeof search === 'object'
                ? search.id
                : search;

            const item = balances.find(coin => coin.id === id);
            return item ? item.bits : "0";
        },

        bipbalance ({ wallet: { balances }}, getters) {
            return getters.balance(defaultCoin);
        },

        findStakes: ({ wallet: { stakes }}) => (validatorPubkey) => {
            return stakes.filter(({ pub_key }) => pub_key == validatorPubkey);
        },

        stakes({ validator: { pubkey }}, getters) {
            return getters.findStakes(pubkey);
        },

        baseCurrency (state, getters) {
            return getters.findCurrency('USD');
        },

        selectedCurrency ({ settings: { currency }}, getters) {
            return getters.findCurrency(currency.toUpperCase());
        },

        findCurrency: ({ exchangeRates }) => (symbol) => {
            const exchangeRate = exchangeRates.find((currency) => {
                return currency.symbol == symbol.toUpperCase();
            });

            return exchangeRate || { symbol, rate: 0 };
        },

        canSendNotifications({ services: { notifications }, settings }) {
            return notifications.supported
                && notifications.allowed
                && settings.notifications;
        },

        findValidator: ({ validators }) => (public_key) => {
            const validator = validators.find(node => node.pub_key == public_key);
            return validator || { 
                public_key, 
                stake: 0,
                part: 0, 
                name: null,
                description: null,
                icon_url: null,
                site_url: null,
            };
        },
    },
});
